import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import "./HomeModal.css";
import Slider from "react-slick";

// modal image
import CommonX from "../images/homeModal/Common X.png";
import CommonXIl from "../images/homeModal/Common XIl.png";
import marathon from "../images/marathon/marathon.png";

// modal Video
import modalVideo from "../images/Video/Video.mp4";

const HomeModalContent = [
    {
        id: 1,
        Image: CommonX,
    },
    {
        id: 1,
        Image: CommonXIl,
    },
];

const HomeModal = ({ show, onHide, cancelButtonRef }) => {
    // var settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 5000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 1000,
    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 speed: 7000,
    //                 autoplay: true,
    //                 infinite: true,
    //                 dots: false,
    //             },
    //         }
    //     ]
    // }

    return (
        <Transition.Root show={show} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={cancelButtonRef}
                onClose={onHide}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto home-modal">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg dilogPanel text-left shadow-xl transition-all bg-white sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="flex justify-end mb-1">
                                    <AiOutlineCloseCircle
                                        className="modal-svg"
                                        onClick={onHide}
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                        fill="rgb(30 154 214)"
                                    />
                                </div>
                                
                                <video loop autoPlay muted>
                                    <source src={modalVideo} type="video/mp4" />
                                </video>
                                {/* <img src={marathon} alt="marathon" /> */}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default HomeModal;
