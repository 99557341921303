// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import PageListSection from "../components/PageListSection";
import PhotoGallery from "../components/PhotoGallery";

// images
import ParentsBgPhoto from "../images/parent-engage-bg.jpg";
import ParentsEngagePhoto from "../images/parent-health.jpg";
import ParentHealthCamp from "../images/parent_engagement_2.jpg";
import ParentCHildComp from "../images/parent-child-comp.jpg";
import ParentTeacherMeet from "../images/parent-teacher-meet.jpg";
import OnlineStressRelief from "../images/online-stress-relief.jpeg";

const ParentsEngagePage = () => {
  const data = [
    {
      heading: "Parent Engagement",
      image: ParentsEngagePhoto,
      content: `These programs are conducted for parents and school staff to work 
                      together and improve the learning of children. It also helps maintain 
                      the enthusiasm of students.`,
    },
    {
      heading: "Health Camps For Parents",
      image: ParentHealthCamp,
      content: `During the pandemic, various online meets and seminars were conducted in 
                      which we gave the parents knowledge about yoga asanas and exercises and 
                      also taught them the benefits of regular exercising. The teachers and 
                      parents together participated in these health camps for not only their 
                      health but to also teach the kids about it.`,
    },
    {
      heading: "Mother-Child Competitions",
      image: ParentCHildComp,
      content: `On 22nd of October, 2021, special Mother Engagement Programme was conducted 
                      in which mother-child together took part in various healthy and fun 
                      competitions. Many events such as Mehendi Competition, Toran and Bandhanwar 
                      Competition,Kalash and Thali Decoration, Folk Dance were held. Winners were 
                      appreciated by giving them certificates.`,
    },
    {
      heading: "Annual Parent-Teacher Meet",
      image: ParentTeacherMeet,
      content: `Every year special parent and teacher meetings are conducted for them to 
                      interact and also discuss the child’s progress throughout the year. In 
                      this meeting, results and over all performance of the students are 
                      discussed for the parents to know the child’s academic and sports’ result. 
                      It also helps the teachers to have a one on one conversation with the 
                      students’ parents and talk about their yearly scholastic achievement`,
    },
    {
      heading: "Online Stress Relieving Programmes",
      image: OnlineStressRelief,
      content: `To help the parents reduce some stress and tension, we organised some 
                      online fun events like Music and Dance camp, mehendi competitions, yoga 
                      training, etc. After the huge success of this online program and the great 
                      responses from the parents, we continue to conduct such events once in a 
                      while. `,
    },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        title="Parent Engagement"
        backgroundImage={ParentsBgPhoto}
        bgImageGradient={0}
        quote="Education is the passport to the future, for tomorrow belongs to those who prepare for it today."
        author="Malcolm X"
      />
      <PageListSection data={data} />
      <PhotoGallery />
    </>
  );
};

export default ParentsEngagePage;
