// packages
import { useLayoutEffect } from "react";

// components
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";

// images
// import success_stories_bg from "../images/success_stories_bg.jfif";
import success_stories_bg from "../images/suceesStory2.jpg";
import PrakritiSharma from "../images/prakriti.jpg";
import RohanKoul from "../images/rohan.jpg";
import AbhishekTalashi from "../images/abhishek.jpg";
import KarandevSingh from "../images/karandev.jpg";
import front_line_warriors from "../images/front_line_warriors_1.jpg";
import faculty_development_programme from "../images/faculty_development_programme.jpg";
import MarathonStory from "../images/MarathonStory.jpg";
import PresidentVisit from "../images/PresidentVisit.jpg";
import UnionMinisterVisit from "../images/UnionMinisterVisit.jpg";
import RegionalOfficerVisit from "../images/RegionalOfficerVisit.jpg";
import beti_padhao from "../images/Coding-2.jpeg";
import students from "../images/scoutsVertical.jpg";

const SuccessStoriesPage = () => {
    let data = [
        {
            heading: "Dr. Prakriti Sharma",
            image: PrakritiSharma,
            content: `“Once, Don Bartolovic said that "A trip to nostalgia now and then is good for 
                      the spirit"! I, being the alumni of this prestigious school, feel immense 
                      pleasure in sharing the most valuable experiences of my life. I joined Hermann Gmeiner School in 
                      5th standard. I used to enjoy the regular classes, lunch breaks and play time 
                      just like any other student. My first inclination in school activities was 
                      towards music and fine arts. For the same reason, I participated in various 
                      music, dance & painting competitions and I must say Vijay ma’am and Shivashish 
                      sir were my source of inspiration. They helped me recognize my true potential. 
                      After 10th standard, I took PCM along with painting as an additional subject. 
                      Among all the subjects, Biology used to be my favourite and it became even easier 
                      when I got the guidance from Nidhi Ma’am. I would like to thank Geeta Moza Ma’am, 
                      Anju Jalali Ma’am and many more for being my pillars of constant support. In 2011, 
                      I appeared for JK CET and in my first attempt, I got selected for the MBBS course 
                      in SKIMS Medical College, Srinagar. It was the happiest day of my life. And for 
                      this accomplishment, I will always be grateful to my school that has made me the 
                      person I am today and for being the wings for my flight. It has taught me to be a 
                      good human, a good learner & a good listener. I have been in the medical profession 
                      since the last 10 years and presently I'm doing my Post Graduation in Dermatology in 
                      GMC, Jammu. So just remember, you can do anything you set up your mind for, but 
                      it takes action, perseverance, and facing your fears.”`,
        },
        {
            heading: "Rohan Koul (Data Scientist - SAP Labs India)",
            image: RohanKoul,
            content: `“After passing Class XII exams from Hermann Gmeiner School, I worked hard for my entrance examinations 
                       and got into the prestigious MIT Pune. Hard work and dedication, which were imbibed 
                       in me while I was at Hermann Gmeiner School, were instrumental in securing this opportunity. In college 
                       my focus was on getting practical knowledge and preparing myself for the industry. I 
                       spent a lot of time developing different types of software and participating in various 
                       national and international competitions. Finally, I got placed at SAP Labs India and 
                       currently I am working as a Data Scientist. My goal is to always keep working hard, 
                       keep learning, keep an open mind, keep growing and staying sincere to my work.”`,
        },
        {
            heading: "Abhishek Talashi (Senior Manager - IDFC Bank Mumbai)",
            image: AbhishekTalashi,
            content: `“I was a student of Hermann Gmeiner Schooll, Jammu from 2006 to 2011, completing my higher 
                       secondary education. The school acted as a portal for me to explore my interest in academics 
                       and sports. The teaching staff was well-versed with all the topics and guided me throughout. 
                       SOS Hermann Gmeiner School was a building block for my higher education and my current occupation.”`,
        },
        {
            heading: "Karandev Singh (Business Analyst - American Express)",
            image: KarandevSingh,
            content: `“I attended Hermann Gmeiner School, Jammu from class 7th to 12th and without a doubt, these were the best years 
                      of my student life. The school provided me with an encouraging and motivating environment, 
                      building a platform for me to excel not only in studies but other extra-curricular activities 
                      as well. The focus was always on holistic development and this set me up strongly to take on 
                      different challenges in life. I will forever be thankful to my teachers who guided me in the 
                      best way possible and had a major role in shaping up my life and pushing me towards success. 
                      I will always cherish the time I spent here, and wish the best to all fellow HGians in their 
                      lives.”`,
        },
    ];

    data = [
        {
            heading: "A Historic Moment!!",
            image: PresidentVisit,
            content: `The bright young minds of <a  href="https://web.facebook.com/HGSBHIMTAL1984" target="_blank"> Hermann Gmeiner School Bhimtal</a>, <a  href="https://web.facebook.com/profile.php?id=100067122677416" target="_blank"> Hermann Gmeiner School Varanasi</a>,
            <a href="https://web.facebook.com/profile.php?id=100064038273299" target="_blank">Hermann Gmeiner Senior Secondary School Bhopal</a> had the incredible opportunity to
            visit our honorable President, Smt. Droupadi Murmu, on the occasion of Rakshabandhan! A
            thought-provoking Question-Answer session took place, where these students showcased their
            curiosity and knowledge, and our President graciously answered their inquiries.<br/>
            This memorable encounter between our nation&#39;s future leaders and our esteemed President will
            surely inspire them to reach for the stars!`,
        },
        {
            heading: "Run A Mile – The Marathon Experience",
            image: MarathonStory,
            content: `
            On August 12, 2023, Hermann Gmeiner Educational Institutions hosted the event &quot;Run a Mile - The
            Marathon Experience.&quot; This event garnered an incredible response, with participation from over
            1000 individuals spanning the age range of 5 to 75 years. The enthusiastic cheers of the audience
            resonated throughout the area, creating an electrifying atmosphere.
            <br/>
            The presence of esteemed local authorities, including MLA, Mr. Rajesh Nagar and the Deputy
            Commissioner of the Police Force, added a profound sense of accomplishment and pride among the
            participants. It was a memorable and successful event that brought the community together to
            celebrate health, fitness, and unity.
            `,
        },
        {
            heading:
                "A Visit from the Union Minister of State for Defence and Tourism",
            image: UnionMinisterVisit,
            content: `
            Hermann Gmeiner School, Bhimtal was truly honored to host <br/> Shri Ajay Bhatt, Union
            Minister of State for Defence and Tourism, at the prestigious investiture ceremony. His
            esteemed presence added a significant level of distinction to the event, reflecting the
            importance of the occasion. As the school celebrated the achievements and responsibilities
            of the students, having a distinguished leader like Shri Ajay Bhatt  in attendance made the
            ceremony even more memorable and inspiring. The school was grateful for his gracious
            presence and support in recognizing and encouraging the bright futures of the young
            leaders.
            `,
        },
        {
            heading: "A visit from Regional Officer of C.B.S.E Bhimtal",
            image: RegionalOfficerVisit,
            content: `
            On June 19, 2023, Hermann Gmeiner School Bhimtal had the esteemed privilege of hosting Mr.
            Ranber Singh, Regional Officer of C.B.S.E. Dehradun Region, at the campus. This visit was
            characterized by a warm and enthusiastic atmosphere, marked by the exchange of valuable insights.
            <br/>
            During his address, Mr. Ranber Singh underscored the profound significance of responsibility in an
            individual&#39;s life. He emphasized that today&#39;s children are the future global ambassadors, encouraging
            them to prioritize physical fitness, excel academically, and nurture a positive and constructive
            mindset.
            <br/>
            To commemorate this special occasion, Mr. Ranber Singh, accompanied by the school principal,
            unveiled the school magazine, &quot;Prayas.&quot; This publication added an extra layer of grandeur to his visit,
            showcasing the remarkable talents and accomplishments of the students

            `,
        },
        {
            heading: "Front Line Warriors During COVID Pandemic (Faridabad)",
            image: front_line_warriors,
            content: `The Covid-19 Pandemic took its toll on the world and India had a share of its own. During this situation, the Nursing School (GNM and ANM) students along with Nursing tutors continued to provide nursing care to the patients at all possible levels, with utmost diligence and care. While this Covid-19 surge was on, the Directorate of Medical Education and Research, Panchkula directed all nursing schools for the deployment of nursing students in Government and Private sectors and students of SOS Nursing School marched forward for this great cause. From April 2021 to November 2021, the 2nd year and final year students of GNM and ANM gathered to provide comprehensive nursing care to the Covid positive patients in Civil hospitals and Asian Institute of Medical Sciences, Faridabad. The students along with nursing tutors were highly appreciated by the Civil surgeon of Faridabad and Director General of DMER, Panchkula. As a result of this hard work and dedication, all of the GNM/ANM/Paramedical students relieved in October 2021 are now successfully placed in different private hospitals.`,
        },
        {
            heading: "Beti Bachao Beti Padhao (Palla Nuh)",
            image: beti_padhao,
            content: `Hermann Gmeiner School Palla, Nuh was established in village Palla as a community project in 1997. The main objective behind it was welfare & educational upliftment of the community's children, especially girls. Since the beginning of the school, education for girls has been the utmost priority. The people of the locality were somewhat rigid and did not prefer to educate girls. Local people would rather send their girls for religious learning than to schools. To educate girls, we organised workshops, door-to-door counselling sessions, home visits of drop-out students, and offered them exciting gifts too. Even after this amount of hard work, there are still few dropouts after sixth grade, for which we have been working day and night. We ensure that every girl is aware of her rights. We take responsibility for the upliftment of girls and do our best in contemplation of the slogan “BETI BACHAO BETI PADHAO”. Due to free education, a good number of girls are benefitted and most of the localities are willing to send their girls for education.`,
        },
        {
            heading: "From 40 Students To 767 Scholars (Bhopal)",
            image: students,
            content: `From 1998 till now, the journey of Hermann Gmeiner Senior Secondary School, Bhopal has been incredible. Starting with 40 students and 6 rooms, today our school has 767 students (on the roll) and an amazing infrastructure. The school is now equipped with all the necessary amenities. From hygienic washrooms, well-equipped laboratories, fully furnished classrooms to playgrounds, we have made this place a haven of education. The school is affiliated with the MP Board of Secondary education and has well-qualified teaching staff members who impart education to the students. The school fosters all-round growth and development of students to their fullest potential, spiritually, intellectually, and physically. The school also has a provision for co-curricular activities to keep their creative quotient in check. Several classes for Yoga, Meditation, Games and Physical Training, Life Skill, etc. are incorporated with main subjects for the overall development of an individual. Due to this hard work and dedication, in 2021, our school achieved a milestone and got upgraded to a Senior Secondary School.`,
        },
        {
            heading: "Faculty Development Programme – 2021",
            image: faculty_development_programme,
            content: `Hermann Gmeiner School not only focuses on the betterment and providing quality education for students but also on the development of the teachers and staff. A good teacher knows how to bring out the best in a student and therefore, Hermann Gmeiner Institute of Education, Bhimtal was established in the year 2005 to prepare the future teachers and is successfully working to achieve the aim of preparing professional and humane teachers. The Institute provides training to the students admitted through the B.Ed. entrance examination. In 2021 the institute decided to provide training to upgrade and update the Hermann Gmeiner School teaching staff and to enhance their teaching skills using modern methods and technology. A seven day Faculty Development Programme from 26th April to 2nd May 2021 was inaugurated by the Institute on “Development of Practical Skills for Effective Classroom Management”. The success of this programme has encouraged Hermann Gmeiner School to continue to work for the betterment of the faculty and staff and also to conduct more of such events and campaigns.`,
        },
    ];

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <TopSection2
                title="Success Stories"
                backgroundImage={success_stories_bg}
                bgImageGradient={0}
            />
            <PageListSection customClass="text-justify" data={data} />
        </>
    );
};

export default SuccessStoriesPage;
