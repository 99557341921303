import React from "react";
import "./BlogPost.css";
import { useNavigate } from "react-router-dom";
const BlogPost = ({ id, date, readTime, title, excerpt, imageUrl }) => {
  const truncateText = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const history = useNavigate();

  const handleClick = () => {
    history(`/post/${id}`);
  };
  return (
    <div className="blog-post" onClick={handleClick}>
      <img src={imageUrl} alt={title} className="blog-image" />
      <div className="blog-content">
        <div className="blog-date">
          {date} • {readTime}
        </div>
        <h2 className="blog-title">{title}</h2>
        <p className="blog-excerpt">{truncateText(excerpt, 100)}</p>
      </div>
    </div>
  );
};

export default BlogPost;
