import JinsiPhoto from "../images/jinsi.png";
import SumantaPhoto from "../images/sumanta.jpg";
import DebarotiPhoto from "../images/debaroti.png";
import AboutUs from "../images/about-us.jpg";
import AboutUsSlogan from "../images/about-us-slogan.png";

const AboutUsList = () => {
    return (
        <div className="w-full mt-12 mb-24 px-8 md:px-20 flex flex-col gap-12 text-[#737373] text-justify text-lg md:text-xl">
            <div className="w-full flex flex-col md:block">
                <div
                    data-aos="fade-right"
                    className="w-full md:w-[400px] md:float-left space-outside-round ml-0 m-20"
                >
                    <img className="w-full" src={AboutUs} alt="" />
                </div>
                <p
                    data-aos="fade-left"
                    data-aos-delay={300}
                    className="md:mt-12"
                >
                    Settling over four generations of once parentless or
                    abandoned children of the country, SOS Children’s Villages
                    of India has served for over 56 years till now. With the
                    inauguration of the first SOS Children’s Village at
                    Greenfields, Faridabad by Indira Gandhi, the organization
                    has now spread all across the country. Every time a natural
                    or man-made disaster has struck an area/region, SOS
                    Children’s Villages have been active in assisting and
                    initiating relief operations and activities, with central
                    importance being given to the affected children. As a
                    result, many SOS Children’s Villages in Hojai, Guwahati,
                    Bhuj, Bhopal, Latur, Bhubaneswar, Rajpura, Jammu, Srinagar,
                    Rourkela, Nagapattinam and Puducherry were born.
                </p>
                <br />
                <p data-aos="fade-left" data-aos-delay={500}>
                    SOS Children’s Villages of India also provides support to
                    families in adverse conditions as a part of its Family
                    Strengthening Programme. The aim of this programme has been
                    to empower biological parents under trying conditions to
                    strengthen their capacities as care givers for their
                    children. The programme has worked as a preventive measure
                    to contain the abandonment of children by their families.
                </p>
                <br />
                <p data-aos="fade-left" data-aos-delay={700}>
                    Today, four generations of once parentless and abandoned
                    children are leading constructive lives. They have an
                    identity, a family to call their own and most importantly, a
                    life that has set examples for others to grow up to become
                    active and contributing members of society.
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-10">
                <div
                    data-aos="fade-right"
                    data-aos-delay={800}
                    className="md:w-2/6"
                >
                    <img className="w-full" src={AboutUsSlogan} alt="" />
                </div>
                <p
                    data-aos="fade-left"
                    data-aos-delay={900}
                    className="md:w-4/6"
                >
                    This has been made possible by the continuous support of
                    donors and friends like you, who come forward to help us
                    change the future of many vulnerable children in India. Now,
                    the big challenge that lies ahead of us is to make our
                    programmes 100% financially sustainable. It is in this hour
                    of need, when our friends and supporters all over the world
                    are facing the financial crunch and with India emerging as a
                    strong self-sustainable nation, the onus has fallen on us to
                    cover our costs and not compromise on the quality care we
                    have been providing all these years, even for a second.
                </p>
            </div>
        </div>
    );
};

const AboutUsContent = () => {
    const data = [
        {
            title: "President",
            name: "Mr. Jinsi",
            image: JinsiPhoto,
            school: "SOS Children’s Villages of India",
            description: `Problems trying to resolve the conflict between the two major 
						  realms of Classical physics: Newtonian mechanics Problems trying 
						  to resolve the conflict between the two major realms of Classical 
						  physics: Newtonian mechanics Problems trying to resolve `,
        },
        {
            title: "Secretary General",
            name: "Mr Sumanta Kar",
            image: SumantaPhoto,
            school: "SOS Children’s Villages of India",
            description: `Problems trying to resolve the conflict between the two major realms of
						  Classical physics: Newtonian mechanics Problems trying to resolve the 
						  conflict between the two major realms of Classical physics: Newtonian 
						  mechanics Problems trying to resolve`,
        },
        {
            title: "Head ECD",
            name: "Mrs Debaroti Bose",
            image: DebarotiPhoto,
            school: "SOS Children’s Villages of India",
            description: `Problems trying to resolve the conflict between the two major 
						  realms of Classical physics: Newtonian mechanics Problems trying 
						  to resolve the conflict between the two major realms of Classical 
						  physics: Newtonian mechanics Problems trying to resolve `,
        },
    ];

    return (
        <div className="w-full py-20">
            {data.map((item, index) => {
                let order =
                    "w-full flex flex-col md:flex-row gap-16 px-12 md:px-20 py-20";
                if (index % 2 === 1) {
                    order += " md:flex-row-reverse";
                }
                return (
                    <div className={order} key={index}>
                        <div className="rounded overflow-hidden">
                            <img className="rounded" src={item.image} alt="" />
                        </div>
                        <div className="md:w-4/5 leading-7">
                            <div className="w-28 h-[5px] bg-[#1e9ad6]"></div>
                            <div className="font-bold text-4xl my-8">
                                {item.title}
                            </div>
                            <div className="font-medium">{item.name}</div>
                            <div className="text-[#858585] mb-4">
                                {item.school}
                            </div>
                            <div className="text-[#737373] text-lg">
                                {item.description}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AboutUsList;
