import PhotoFrameVertical from "../images/photoframe-vertical.png";
import PhotoFrameHorizontal from "../images/photoframe.png";
import alumni1 from "../images/user-cover-2.jpg";
import { useLocation } from "react-router-dom";

const AlumniListSection = ({ data, limit }) => {
    if (data === undefined) {
        data = [
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
            {
                name: "Name of Alumni",
                image: alumni1,
                description: "Profession",
            },
        ];
    }
    if (limit === undefined) {
        limit = data.length;
    }
    let location = useLocation();
    return (
        <div className="w-full px-12 pb-32 md:px-40 md:pb-40">
            {/* <div className="max-w-full justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 mt-20 gap-16"> */}
            <div className="max-w-full justify-items-center flex flex-wrap justify-center mt-20 gap-12">
                {data.slice(0, limit).map((alumni, index) => {
                    return (
                        <div
                            data-aos="zoom-in"
                            data-aos-delay={600}
                            className="flex flex-col overflow-hidden shadow-xl w-[300px] pl-[32px] pr-[34px] py-[23px] z-10"
                            key={index}
                            style={{
                                backgroundImage: `url(${PhotoFrameVertical})`,
                                backgroundSize: "100% 100%",
                            }}
                        >
                            <div className="">
                                <div
                                    className="h-[200px] w-full overflow-hidden bg-cover bg-no-repeat bg-top"
                                    style={{
                                        backgroundImage: `url(${alumni.image})`,
                                    }}
                                ></div>
                                <div className="flex flex-col gap-0 items-center text-center p-2">
                                    <div className="font-semibold">
                                        {alumni.name}
                                    </div>
                                    <div className="text-[#737373] text-sm">
                                        {alumni.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AlumniListSection;
