import React from "react";



const InstitutionalPlanner = () => {
    const pdfFiles = [
        { name: 'Institutional Planner, Hermann Gmeiner School Bhopal', url: '../Bhopal Institutional Planner.pdf'},
        { name: 'Institutional Planner, Hermann Gmeiner School Bhimtal', url: '../Bhimtal Institutionla Planner 2024 -25.pdf'},
        { name: 'Institutional Planner, Hermann Gmeiner School Anangpur', url: '../Anangpur Institutional Planner.pdf'},
        { name: 'Institutional Planner, Hermann Gmeiner School Palla Nuh', url: '../Palla Nuh INSTITUTIONAL PLANNER 2024-25.pdf'},
        { name: 'Institutional Planner, Hermann Gmeiner School Varansi', url: '../Academic-Calendar-2024-2025.xlsx'},
        { name: 'Institutional Planner, Hermann Gmeiner School Jammu', url: '../FINAL REVISED INSTITUTIONAL PLAN 2024-25 (1).pdf'},
        { name: 'Institutional Planner, Hermann Gmeiner School Faridabad', url: '../ANNUAL ACTIVITY CALENDAR-2024-25.xlsx'},
      ];
 
  return (
    <div className="home_main">
      <div className="pdf-container">
      <table className="pdf-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Filename</th>
            <th>Link To Documents</th>
          </tr>
        </thead>
        <tbody>
          {pdfFiles.map((file, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{file.name}</td>
              <td>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

      
    </div>
  );
};

export default InstitutionalPlanner;
