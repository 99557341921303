// // packages
// import { useLayoutEffect } from "react";

// // containers
// import TopSection2 from "../components/TopSection2";
// import PageListSection from "../components/PageListSection";
// import Phone from "../images/phone.png";
// import PedagogiSkill from "./PedagogiSkill";
// // images
// import CareersCover from "../images/careers_cover.jpg"
// import topicon from "../images/pedagogyTopIcon.svg";

// const Careers = () => {

//   useLayoutEffect(() => {
//     window.scrollTo(0, 0);
//   });
//   const schoolLinks = [
//     {
//       name: "Hermann Gmeiner Educational Institutions",
//       link: "www.hermanngmeinereducationalinstitutions.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Bhimtal",
//       link: "www.hermanngmeinerschoolbhimtal.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Varanasi",
//       link: "www.hermanngmeinerschoolvaranasi.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Jammu",
//       link: "www.hermanngmeinerschooljammu.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Faridabad",
//       link: "www.hermanngmeinerschoolfaridabad.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Bhopal",
//       link: "www.hermanngmeinerschoolbhopal.org"
//     },
//     {
//       name: "Hermann Gmeiner School, Anangpur (Primary)",
//       link: "www.hermanngmeinerschoolanangpur.org"
//     },
//     {
//       name: "Hermann Gmeiner Degree College",
//       link: "Domain in process"
//     },
//     {
//       name: "SOS Nursing School",
//       link: "www.sosnursingschool.org"
//     }
//   ];

//   return (
//     <>
//       <TopSection2
//         // title="Pedagogy"
//         backgroundImage={CareersCover}
//         bgImageGradient={0}
//       // quote="Children must be taught how to think, not what to think."
//       // author="Margaret Mead"
//       />

//       <div className="w-full h-full px-12 md:px-20 py-6 snap-center">
//         <div className="flex">
//           <img
//             className="pedagogi_image"
//             src={topicon}
//             alt=""
//             style={{ width: "80px" }}
//           />
//           <h1 className="text-4xl mt-4 font-semibold text-[#1fdc00d1]">

//           </h1>
//         </div>
//         <div className="flex gap-2">
//           <div>
//             <div className="text-[#000000b8]  text-1xl mx-6 my-12">
//               <ol>
//                 <li>
//                   * <span className=" font-semibold">Position</span> : Principal for Hermann Gmeiner School, Jammu, CBSE Affiliated, Sr. Secondary School
//                 </li>
//                 <li>
//                   * <span className=" font-semibold">Organization</span> : Hermann Gmeiner Educational Institutions under SOS Children’s Villages India
//                 </li>
//                 <li>
//                   * <span className=" font-semibold" >Location</span> : Jammu, Jammu and Kashmir
//                 </li>
//               </ol>
//             </div>
//             <div className="text-[#000000c4]  mx-6 my-8">
//               <h3><span className=" font-semibold"> About the Organization</span> <br></br>
//                 Hermann Gmeiner Educational Institutions under the trust of SOS Children’s Village launched its first school in 1986 in Faridabad, aiming to provide meticulous attention to detail in creating a tuition-free education. The focus is on holistic development, encompassing life skills education, STEM education, coding, robotics, and financial literacy. To foster transformative student life experiences, the institutions utilize technology-driven classes and offer complimentary counseling sessions to assess both mental and physical well-being. All our schools adhere to the National Education Policy 2020 and are equipped with STEM Labs, Computer Labs, and the necessary infrastructure, including extensive sports facilities. All Hermann Gmeiner Institutions are self-sustaining, promoting 21st-century skills and fostering global citizenship.
//                 We strive to inspire positive change and cultivate transformative future leaders. Our commitment to academic rigor and innovative learning experiences allows students to embark on an exceptional educational journey with us.
//                 Today, we operate four Senior Secondary CBSE Schools located in Faridabad, Jammu, Varanasi, and Bhimtal. The Hermann Gmeiner School in Bhimtal also offers day and boarding options. Additionally, we have four State Board Schools: Hermann Gmeiner School in Bhopal (Senior Secondary School), Hermann Gmeiner School in Palla Nuh (Senior Secondary School), and Hermann Gmeiner Schools in Bhuj and Anangpur (Primary Schools). Beyond traditional courses, we have a Nursing School in Anangpur and a Degree College in Bhimtal. To enhance employability skills, we have successfully established Vocational Training Centres in Nizamuddin, Raipur, and Bhakatpara. Moreover, Vocational Training Centres and after-school classes are now a part of all Hermann Gmeiner Schools.</h3>
//             </div>
//             {/* <div className=" my-15 flex justify-center">
//               <img className="mb-16" src={p1} />
//             </div> */}
//             <div className="text-[#000000c4] font-medium mx-6 my-4">
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 {schoolLinks.map((school, index) => (
//                   <li key={index} style={{ marginBottom: '10px' }}>
//                     {school.name} -{' '}
//                     <a
//                       href={`http://${school.link}`}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       style={{ textDecoration: 'underline', color: 'blue' }}
//                     >
//                       {school.link}
//                     </a>
//                   </li>
//                 ))}
//               </ul>
//               {/* <br /> */}
//             </div>

//             <div className="text-[#000000c4]  mx-6 my-4 ">
//               <h2 className="font-semibold">Areas of Responsibility:</h2>

//               <h3 className="font-semibold">Position Purpose (Mission):</h3>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>To strengthen the academic, administrative and co-curricular activities in Hermann Gmeiner Schools.</li>
//                 <li>To develop a highly motivated faculty to deliver the quality services to produce best results.</li>
//               </ul>
//               <br />
//               <h3>Key Performance Areas and Main Responsibilities:</h3>

//               <br />
//               <h4 className="font-semibold">Leadership and Management:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Provide inspiration and strong leadership to the Heads of School/Coordinators and the teaching teams to ensure that the school continues to deliver the highest standards of learning and care across the board.</li>
//                 <li>Lead by example and embody the school’s vision and values for the leadership team, pupils, staff, and Governors.</li>
//                 <li>Lead and manage all academic and administrative staff at the SOS school. This will include appointments, appraisals, training and development, and when necessary initiate the implementation of disciplinary procedures and make reports to the Director/Managing Committee/Governing Body when necessary.</li>
//                 <li>Treat all people fairly, equitably and with dignity and respect to create and maintain a positive school culture in line with the school’s Mission Statement.</li>
//                 <li>Ensure the effective implementation of all school policies, including those that relate to pastoral care, such as behavior, discipline and bullying.</li>
//                 <li>Foster warm and positive relationships between the school, the local community and all stakeholders.</li>
//                 <li>Acknowledge the responsibilities and celebrate the achievements of individuals and teams.</li>
//                 <li>Develop and maintain a realistic culture of high expectations for self and for others and take appropriate action when performance is unsatisfactory</li>
//                 <li>Regularly review own practice, set personal targets and take responsibility for own personal development.</li>
//                 <li>Responsible for ensuring the safety and wellbeing of the pupils at the school;</li>
//                 <li>Responsible to the Director/Managing Committee/GB for all matters pertaining to SOS.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Learning and Teaching:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Ensure a culture and ethos of challenge and support where all pupils can achieve success and become engaged in their own learning.</li>
//                 <li>Assess, monitor and evaluate the quality of teaching standards and learning and the delivery of the curriculum across the board in order to build on success and identify and act on areas of improvement.</li>
//                 <li>Continue to develop and review curriculum for SOS in keeping with our changing world.</li>
//                 <li>Monitor and evaluate pupils' attainment levels.</li>
//                 <li>Demonstrate excellent classroom practice;</li>
//                 <li>Teach a timetable commensurate with the responsibilities if required; Monitor the teaching and learning throughout the school;</li>
//                 <li>Encourage the further development of a wide-ranging extra-curricular activities programme, socio-emotional learning, and gender & diversity studies.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Organization – Managing Systems and Resources:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Implement a framework of effective evaluation, assessment and performance management which engages the whole school community. Provide effective organization and management of the school and seek ways of improving organization structures and functions based on rigorous self-evaluation. Take a strategic role in the development of new and emerging technologies to extend and enhance the learning experience of pupils and the wider school community, actively engaging with other schools to build effective learning communities.</li>
//                 <li>Manage and organize the accommodation effectively and efficiently to ensure that all school buildings meet the needs of the curriculum and health and safety regulations.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Strategic Direction and Shaping the Future:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Work with the Director and Adviser to maintain a shared vision and future strategic plans which will inspire and motivate pupils, staff, parents and the wider community.</li>
//                 <li>Ensure that learning is at the center of strategic planning and resource management.</li>
//                 <li>Monitor and review all aspects of teaching, attainment, priorities, and targets.</li>
//                 <li>Promote excellence, equality and high expectations of all pupils, and the wider school community, ensuring a consistent and continuous school-wide focus on pupils’ achievement.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Community:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Create and maintain a strong sense of community with effective partnerships with all stakeholders that supports and improves pupils’ achievements and personal development.</li>
//                 <li>Continue to maintain the school culture and ethos.</li>
//                 <li>Actively promote the school as a center of excellence for education in the local community.</li>
//                 <li>Continue to develop links within both the school and the wider community, enabling and promoting excellent communication and relationships between all parties, including neighboring schools, and all outside support agencies.</li>
//                 <li>Exercise responsibility for ensuring fair and equal treatment of all employees and customers.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Staff Management and Development:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Develop effective relationships and communications, which underpin a professional learning community that enables everyone in the school to achieve.</li>
//                 <li>Create an organizational structure that reflects the school’s values and enables the management systems, structure and processes to work effectively in line with key priorities.</li>
//                 <li>Ensure effective planning, allocation, support and evaluation of work undertaken by teams and individuals, ensuring clear delegation of tasks and devolution of responsibilities.</li>
//                 <li>Provide opportunities for growth and continuing professional development of the teaching team by creating an inspiring, professional work environment and modeling behavior consistent with the school’s values and aspirations.</li>
//                 <li>Ensure that all staff receive regular performance reviews and have individual professional development plans to address skills gaps.</li>
//                 <li>Put in place effective team communication mechanisms to ensure that all staff are involved in the school development plan and kept informed of key priorities and developments.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Accountability:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Ensure individual staff accountabilities are clearly defined, understood and agreed and are subject to rigorous review and evaluation.</li>
//                 <li>Create and develop an organization in which all staff recognize that they are accountable for the success of the school. Ensure that all stakeholders are well informed about the school’s direction, priorities, curriculum and pupils’ attainment and progress.</li>
//                 <li>Combine the outcomes of regular school self-evaluation with external evaluations in order to develop the school.</li>
//                 <li>Exercise responsibility for ensuring that all new employees receive an induction programme and are properly inducted in the school’s policies and procedures.</li>
//               </ul>

//               <br />
//               <h4 className="font-semibold">Essential Values, Behaviors and Skills:</h4>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Change orientation</li>
//                 <li>Execution orientation</li>
//                 <li>Operational Excellence</li>
//                 <li>Passion to serve</li>
//                 <li>Relationship Building</li>
//                 <li>Strategic Thinking</li>
//                 <li>Providing Leadership</li>
//               </ul>
//               <br />

//               <h2 className="font-semibold">Education</h2>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Master’s Degree with minimum 55%</li>
//                 <li>Regular B. Ed degree with minimum 55%</li>
//                 <li>CTET qualified is desirable</li>
//               </ul>
//               <br />
//               <h2 className="font-semibold">Experience</h2>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Minimum 10 years of teaching experience and at least 5 years of administrative experience in a recognized Higher Secondary School.</li>
//               </ul>
//               <br />
//               <h2 className="font-semibold">Languages</h2>
//               <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
//                 <li>Excellent communication skills in English and Hindi. Must have significant and proven writing, presentation and people management skills.</li>
//               </ul>
//               <br />
//               <h2 className="font-semibold">Application process</h2>

//               <p>
//                 Please email your CV to <a href="mailto:operations.schools@hermann-gmeiner-edu.org" style={{ color: 'blue', textDecoration: 'underline' }}>operations.school@hermann-gmeiner-edu.org</a> with the subject “<span className="font-semibold">Principal, Hermann Gmeiner School, Jammu</span>”.
//               </p>

//             </div>

//           </div>
//         </div>
//       </div>



//       {/* <PageListSection data={data} /> */}
//     </>
//   );
// };

// export default Careers; 

// Importing necessary packages and components
// import { useLayoutEffect } from "react";
// import TopSection2 from "../components/TopSection2";
// import CareersCover from "../images/careers_cover.jpg";
// import topicon from "../images/pedagogyTopIcon.svg";

// // The Careers component definition
// const Careers = () => {

//   // Scroll to top effect on component mount
//   useLayoutEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   // School links data
//   const schoolLinks = [
//     { name: "Hermann Gmeiner Educational Institutions", link: "www.hermanngmeinereducationalinstitutions.org" },
//     { name: "Hermann Gmeiner School, Bhimtal", link: "www.hermanngmeinerschoolbhimtal.org" },
//     { name: "Hermann Gmeiner School, Varanasi", link: "www.hermanngmeinerschoolvaranasi.org" },
//     { name: "Hermann Gmeiner School, Jammu", link: "www.hermanngmeinerschooljammu.org" },
//     { name: "Hermann Gmeiner School, Faridabad", link: "www.hermanngmeinerschoolfaridabad.org" },
//     { name: "Hermann Gmeiner School, Bhopal", link: "www.hermanngmeinerschoolbhopal.org" },
//     { name: "Hermann Gmeiner School, Anangpur (Primary)", link: "www.hermanngmeinerschoolanangpur.org" },
//     { name: "Hermann Gmeiner Degree College", link: "Domain in process" },
//     { name: "SOS Nursing School", link: "www.sosnursingschool.org" }
//   ];

//   // JSX structure of the Careers component
//   return (
//     <>
//       <TopSection2
//         backgroundImage={CareersCover}
//         bgImageGradient={0}
//       />

//       <div className="w-full h-full px-4 md:px-12 py-6 snap-center">
//         <div className="flex items-center gap-4">
//           <img className="pedagogi_image w-20" src={topicon} alt="Top Icon" />
//           <h1 className="text-2xl md:text-4xl font-semibold text-green-600">Careers</h1>
//         </div>

//         <div className="mt-6 space-y-12">
//           <div className="text-gray-700 text-sm md:text-base mx-2 md:mx-6">
//             <ol>
//               <li>* <span className="font-semibold">Position</span>: Principal for Hermann Gmeiner School, Jammu, CBSE Affiliated, Sr. Secondary School</li>
//               <li>* <span className="font-semibold">Organization</span>: Hermann Gmeiner Educational Institutions under SOS Children’s Villages India</li>
//               <li>* <span className="font-semibold">Location</span>: Jammu, Jammu and Kashmir</li>
//             </ol>
//           </div>

//           <div className="text-gray-800 mx-2 md:mx-6">
//             <h3><span className="font-semibold">About the Organization</span><br />
//               Hermann Gmeiner Educational Institutions under the trust of SOS Children’s Village launched its first school in 1986 in Faridabad, aiming to provide meticulous attention to detail in creating a tuition-free education. The focus is on holistic development, encompassing life skills education, STEM education, coding, robotics, and financial literacy. To foster transformative student life experiences, the institutions utilize technology-driven classes and offer complimentary counseling sessions to assess both mental and physical well-being. All our schools adhere to the National Education Policy 2020 and are equipped with STEM Labs, Computer Labs, and the necessary infrastructure, including extensive sports facilities. All Hermann Gmeiner Institutions are self-sustaining, promoting 21st-century skills and fostering global citizenship. We strive to inspire positive change and cultivate transformative future leaders. Our commitment to academic rigor and innovative learning experiences allows students to embark on an exceptional educational journey with us. Today, we operate four Senior Secondary CBSE Schools located in Faridabad, Jammu, Varanasi, and Bhimtal. The Hermann Gmeiner School in Bhimtal also offers day and boarding options. Additionally, we have four State Board Schools: Hermann Gmeiner School in Bhopal (Senior Secondary School), Hermann Gmeiner School in Palla Nuh (Senior Secondary School), and Hermann Gmeiner Schools in Bhuj and Anangpur (Primary Schools). Beyond traditional courses, we have a Nursing School in Anangpur and a Degree College in Bhimtal. To enhance employability skills, we have successfully established Vocational Training Centres in Nizamuddin, Raipur, and Bhakatpara. Moreover, Vocational Training Centres and after-school classes are now a part of all Hermann Gmeiner Schools.
//             </h3>
//           </div>

//           <div className="text-gray-800 font-medium mx-2 md:mx-6">
//             <ul className="list-disc pl-5">
//               {schoolLinks.map((school, index) => (
//                 <li key={index} className="mb-2">
//                   {school.name} -{' '}
//                   <a
//                     href={`http://${school.link}`}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="text-blue-500 underline"
//                   >
//                     {school.link}
//                   </a>
//                 </li>
//               ))}
//             </ul>
//           </div>

//           <div className="text-gray-800 mx-2 md:mx-6">
//             <h2 className="font-semibold">Areas of Responsibility:</h2>
//             <h3 className="font-semibold">Position Purpose (Mission):</h3>
//             <ul className="list-disc pl-5">
//               <li>To strengthen the academic, administrative and co-curricular activities in Hermann Gmeiner Schools.</li>
//               <li>To develop a highly motivated faculty to deliver the quality services to produce best results.</li>
//             </ul>
//             {/* Add more sections as needed */}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Careers;

// final changes full content 


// packages
import { useLayoutEffect } from "react";

// containers
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";
import Phone from "../images/phone.png";
import PedagogiSkill from "./PedagogiSkill";
// images
import CareersCover from "../images/careers_cover.jpg";
import topicon from "../images/pedagogyTopIcon.svg";

const Careers = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const schoolLinks = [
    {
      name: "Hermann Gmeiner Educational Institutions",
      link: "www.hermanngmeinereducationalinstitutions.org"
    },
    {
      name: "Hermann Gmeiner School, Bhimtal",
      link: "www.hermanngmeinerschoolbhimtal.org"
    },
    {
      name: "Hermann Gmeiner School, Varanasi",
      link: "www.hermanngmeinerschoolvaranasi.org"
    },
    {
      name: "Hermann Gmeiner School, Jammu",
      link: "www.hermanngmeinerschooljammu.org"
    },
    {
      name: "Hermann Gmeiner School, Faridabad",
      link: "www.hermanngmeinerschoolfaridabad.org"
    },
    {
      name: "Hermann Gmeiner School, Bhopal",
      link: "www.hermanngmeinerschoolbhopal.org"
    },
    {
      name: "Hermann Gmeiner School, Anangpur (Primary)",
      link: "www.hermanngmeinerschoolanangpur.org"
    },
    {
      name: "Hermann Gmeiner Degree College",
      link: "Domain in process"
    },
    {
      name: "SOS Nursing School",
      link: "www.sosnursingschool.org"
    }
  ];

  return (
    <>
      <TopSection2
        backgroundImage={CareersCover}
        bgImageGradient={0}
      />

      <div className="w-full h-full px-4 sm:px-8 md:px-12 lg:px-20 py-6 snap-center">
        <div className="flex items-center justify-between">
          <img
            className="pedagogi_image w-20 sm:w-24 md:w-28 lg:w-32"
            src={topicon}
            alt="Pedagogy Icon"
          />
          <h1 className="text-2xl sm:text-3xl md:text-4xl mt-4 font-semibold text-[#1fdc00d1]">
            Careers
          </h1>
        </div>
        <div className="flex flex-col gap-2 mt-4">
          <div>
            <div className="text-[#000000b8] text-sm sm:text-base md:text-lg mx-4 sm:mx-6 my-4 sm:my-12">
              <ol>
                <li>
                  * <span className="font-semibold">Position 1</span> : Principal for Hermann Gmeiner School Bhuj, Gujrat State Board,
Secondary School
                </li>
                <li>
                  * <span className="font-semibold">Organization</span> : Hermann Gmeiner Educational Institutions under SOS Children’s Villages India
                </li>
                <li>
                  * <span className="font-semibold">Location</span> : Bhuj, Gujarat
                </li>
              </ol>
            </div>

            <div className="text-[#000000c4] mx-4 sm:mx-6 my-4 sm:my-8">
              <h3>
                <span className="font-semibold text-lg ">About the Organization</span>
                <br />
                Hermann Gmeiner Educational Institutions under the trust of SOS Children’s Village launched its first school in 1986 in Faridabad, aiming to provide meticulous attention to detail in creating a tuition-free education. The focus is on holistic development, encompassing life skills education, STEM education, coding, robotics, and financial literacy. To foster transformative student life experiences, the institutions utilize technology-driven classes and offer complimentary counseling sessions to assess both mental and physical well-being. All our schools adhere to the National Education Policy 2020 and are equipped with STEM Labs, Computer Labs, and the necessary infrastructure, including extensive sports facilities. All Hermann Gmeiner Institutions are self-sustaining, promoting 21st-century skills and fostering global citizenship.
                We strive to inspire positive change and cultivate transformative future leaders. Our commitment to academic rigor and innovative learning experiences allows students to embark on an exceptional educational journey with us.
                Today, we operate four Senior Secondary CBSE Schools located in Faridabad, Jammu, Varanasi, and Bhimtal. The Hermann Gmeiner School in Bhimtal also offers day and boarding options. Additionally, we have four State Board Schools: Hermann Gmeiner School in Bhopal (Senior Secondary School), Hermann Gmeiner School in Palla Nuh (Senior Secondary School), and Hermann Gmeiner Schools in Bhuj and Anangpur (Primary Schools). Beyond traditional courses, we have a Nursing School in Anangpur and a Degree College in Bhimtal. To enhance employability skills, we have successfully established Vocational Training Centres in Nizamuddin, Raipur, and Bhakatpara. Moreover, Vocational Training Centres and after-school classes are now a part of all Hermann Gmeiner Schools.
              </h3>
            </div>
            <div className="text-[#000000c4] font-medium mx-4 sm:mx-6 my-4">
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {schoolLinks.map((school, index) => (
                  <li key={index} style={{ marginBottom: '10px' }}>
                    {school.name} -{' '}
                    <a
                      href={`http://${school.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', color: 'blue' }}
                    >
                      {school.link}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="text-[#000000c4] mx-4 sm:mx-6 my-4">
              <h2 className="font-semibold text-lg">Areas of Responsibility:</h2>

              <h3 className="font-semibold">Position Purpose (Mission):</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>To strengthen the academic, administrative, and co-curricular activities in Hermann Gmeiner Schools.</li>
                <li>To develop a highly motivated faculty to deliver quality services to produce the best results.</li>
              </ul>
              <br />
              <h3 className="font-semibold text-lg ">Key Performance Areas and Main Responsibilities:</h3>

              {/* <br /> */}
              <h4 className="font-semibold">Leadership and Management:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Provide inspiration and strong leadership to the Heads of School/Coordinators and the teaching teams to ensure that the school continues to deliver the highest standards of learning and care across the board.</li>
                <li>Lead by example and embody the school’s vision and values for the leadership team, pupils, staff, and Governors.</li>
                <li>Lead and manage all academic and administrative staff at the SOS school. This will include appointments, appraisals, training and development, and when necessary initiate the implementation of disciplinary procedures and make reports to the Director/Managing Committee/Governing Body when necessary.</li>
                <li>Treat all people fairly, equitably and with dignity and respect to create and maintain a positive school culture in line with the school’s Mission Statement.</li>
                <li>Ensure the effective implementation of all school policies, including those that relate to pastoral care, such as behavior, discipline and bullying.</li>
                <li>Foster warm and positive relationships between the school, the local community and all stakeholders.</li>
                <li>Acknowledge the responsibilities and celebrate the achievements of individuals and teams.</li>
                <li>Develop and maintain a realistic culture of high expectations for self and for others and take appropriate action when performance is unsatisfactory.</li>
                <li>Regularly review own practice, set personal targets and take responsibility for own personal development.</li>
                <li>Responsible for ensuring the safety and wellbeing of the pupils at the school;</li>
                <li>Responsible to the Director/Managing Committee/GB for all matters pertaining to SOS.</li>
              </ul>
              <br />
              <h4 className="font-semibold">Learning and Teaching:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Ensure a culture and ethos of challenge and support where all pupils can achieve success and become engaged in their own learning.</li>
                <li>Assess, monitor, and evaluate the quality of teaching standards and learning and the delivery of the curriculum across the board in order to build on success and identify and act on areas of improvement.</li>
                <li>Continue to develop and review curriculum for SOS in keeping with our changing world.</li>
                <li>Monitor and evaluate pupils' attainment levels.</li>
                <li>Demonstrate excellent classroom practice.</li>
                <li>Teach a timetable commensurate with the responsibilities if
                  required.</li>
                <li> Monitor the teaching and learning throughout the school.</li>
                <li> Encourage the further development of a wide ranging extra-curricular activities programme, socio emotional learning, and gender & diversity studies.</li>
              </ul>

              <br />
              <h4 className="font-semibold">Organization – Managing Systems and Resources:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>

                <li>Implement a framework of effective evaluation, assessment and performance management which engages the whole school community. </li>
                <li> Provide effective organisation and management of the school and seek ways of improving organisation structures and functions based on rigorous self-evaluation. </li>
                <li> Take a strategic role in the development of new and emerging technologies to extend and enhance the learning experience of pupils and the wider school community, actively engaging with other schools to build effective learning communities.</li>
                <li>Manage and organise the accommodation effectively and efficiently to ensure that all school buildings meet the needs of the curriculum and health and safety regulations.</li>

              </ul>

              <br />
              <h4 className="font-semibold">Strategic Direction and Shaping the Future:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Work with the Director and Adviser to maintain a shared vision and future strategic plans which will inspire and motivate pupils, staff, parents and the wider community.</li>
                <li>Ensure that learning is at the centre of strategic planning and resource management.</li>
                <li>Monitor and review all aspects of teaching, attainment, priorities, and targets.</li>
                <li>Promote excellence, equality and high expectations of all pupils, and the wider school community, ensuring a consistent and continuous school wide focus on pupils’ achievement</li>
              </ul>
            </div>

            <div className="text-[#000000c4] mx-4 sm:mx-6 my-4">
              <h2 className="font-semibold">Community:</h2>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Create and maintain a strong sense of community with effective partnerships with all stakeholders that supports and improves pupil’s achievements and personal development.</li>
                <li>Continue to maintain the school culture and ethos.</li>
                <li>Actively promote the school as a centre of excellence for education in the local community.</li>
                <li>Continue to develop links within both the school and the wider community, enabling and promoting excellent communication and relationships between all parties, including neighbouring schools, and all outside support agencies.</li>
                <li>Exercise responsibility for ensuring fair and equal treatment of all employees and customers.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Staff Management and Development:</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Develop effective relationships and communications, which underpin a professional learning community that enables everyone in the school to achieve.</li>
                <li>Create an organisational structure that reflects the school’s values and enables the management systems, structure and processes to work effectively in line with key priorities.</li>
                <li>Ensure effective planning, allocation, support and evaluation of work undertaken by teams and individuals, ensuring clear delegation of tasks and devolution of responsibilities.</li>
                <li>Provide opportunities for growth and continuing professional development of the teaching team by creating an inspiring, professional work environment and modelling behaviour consistent with the school’s values and aspirations.</li>
                <li>Ensure that all staff receive regular performance reviews and have individual professional development plans to address skills gaps.</li>
                <li>Put in place effective team communication mechanisms to ensure that all staff are involved in the school development plan and kept informed of key priorities and developments.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Accountability:</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Ensure individual staff accountabilities are clearly defined, understood and agreed and are subject to rigorous review and evaluation.</li>
                <li>Create and develop an organisation in which all staff recognise that they are accountable for the success of the school. Ensure that all stakeholders are well informed about the school’s direction, priorities, curriculum and pupils’ attainment and progress.</li>
                <li>Combine the outcomes of regular school self-evaluation with external evaluations in order to develop the school.</li>
                <li>Exercise responsibility for ensuring that all new employees receive an induction programme and are properly inducted in the school’s policies and procedures.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Essential Values, Behaviors and Skills</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Change orientation</li>
                <li>Execution orientation</li>
                <li>Operational Excellence</li>
                <li>Passion to serve</li>
                <li>Relationship Building</li>
                <li>Strategic Thinking</li>
                <li>Providing Leadership</li>
              </ul>
              <br />

              <h3 className="font-semibold">Education</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Master’s Degree with minimum 55%</li>
                <li>Regular B. Ed degree with minimum 55%</li>
                <li>CTET qualified is desirable</li>
              </ul>
              <br />


              <h3 className="font-semibold">Experience</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Minimum 10 years of teaching experience and at least 5 years of administrative experience in a recognized Higher Secondary School.</li>
              </ul>
              <br />


              <h3 className="font-semibold">Languages</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Excellent communication skills English and Hindi. Must have significant and proven writing, presentation and people management skills.</li>
              </ul>
              <br />


              <h3 className="font-semibold">Application process</h3>
              {/* <h5 className="font-semibold"> Jammu branch</h5> */}
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  Please email your CV to{' '}
                  <a
                    href="mailto:operations.schools@hermann-gmeiner-edu.org"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    operations.schools@hermann-gmeiner-edu.org
                  </a>{' '}
                  with the subject “Principal, Hermann Gmeiner School, Bhuj".
                </li>

              </ul>







              <br />
              <br />
              <hr />
              <br />
              <div className="text-[#000000b8] text-sm sm:text-base md:text-lg">
                <ol>
                  <li>
                    * <span className="font-semibold">Position 2</span> : Principal for Hermann Gmeiner School Jammu, CBSE Affiliated, Sr. Secondary School      
                    Secondary School
                  </li>
                  <li>
                    * <span className="font-semibold">Organization</span> : Hermann Gmeiner Educational Institutions under SOS Children’s Villages India
                  </li>
                  <li>
                    * <span className="font-semibold">Location</span> : Jammu , Jammu and Kashmir
                  </li>
                </ol>
              </div>

              <br />

              <div className="text-[#000000c4]">
                <h3>
                  <span className="font-semibold text-lg ">About the Organization</span>
                  <br />
                  Hermann Gmeiner Educational Institutions under the trust of
                  SOS Children’s Village launched its first school in 1986 in
                  Faridabad, aiming to provide meticulous attention to detail in
                  creating a tuition-free education. The focus is on holistic
                  development, encompassing life skills education, STEM
                  education, coding, robotics, and financial literacy. To foster
                  transformative student life experiences, the institutions utilize
                  technology-driven classes and offer complimentary counseling
                  sessions to assess both mental and physical well-being. All our
                  schools adhere to the National Education Policy 2020 and are
                  equipped with STEM Labs, Computer Labs, and the necessary
                  infrastructure, including extensive sports facilities. All
                  Hermann Gmeiner Institutions are self-sustaining, promoting
                  21st-century skills and fostering global citizenship. We strive to
                  inspire positive change and cultivate transformative future
                  leaders. Our commitment to academic rigor and innovative
                  learning experiences allows students to embark on an
                  exceptional educational journey with us. Today, we operate
                  four Senior Secondary CBSE Schools located in Faridabad,
                  Jammu, Varanasi, and Bhimtal. The Hermann Gmeiner School
                  in Bhimtal also offers day and boarding options. Additionally,
                  we have four State Board Schools: Hermann Gmeiner School in
                  Bhopal (Senior Secondary School), Hermann Gmeiner School in
                  Palla Nuh (Senior Secondary School), and Hermann Gmeiner
                  Schools in Bhuj and Anangpur (Primary Schools). Beyond
                  traditional courses, we have a Nursing School in Anangpur and
                  a Degree College in Bhimtal. To enhance employability skills,
                  we have successfully established Vocational Training Centres
                  in Nizamuddin, Raipur, and Bhakatpara. Moreover, Vocational
                  Training Centres and after-school classes are now a part of all
                  Hermann Gmeiner Schools.
                </h3>
              </div>




              <div className="text-[#000000c4] font-medium ">
                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                  {schoolLinks.map((school, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>
                      {school.name} -{' '}
                      <a
                        href={`http://${school.link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'underline', color: 'blue' }}
                      >
                        {school.link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

            </div>

            <div className="text-[#000000c4] mx-4 sm:mx-6 my-4">
              <h2 className="font-semibold text-lg">Areas of Responsibility:</h2>

              <h3 className="font-semibold">Position Purpose (Mission):</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>To strengthen the academic, administrative, and co-curricular activities in Hermann Gmeiner Schools.</li>
                <li>To develop a highly motivated faculty to deliver quality services to produce the best results.</li>
              </ul>
              <br />
              <h3 className="font-semibold text-lg ">Key Performance Areas and Main Responsibilities:</h3>

              {/* <br /> */}
              <h4 className="font-semibold">Leadership and Management:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Provide inspiration and strong leadership to the Heads of School/Coordinators and the teaching teams to ensure that the school continues to deliver the highest standards of learning and care across the board.</li>
                <li>Lead by example and embody the school’s vision and values for the leadership team, pupils, staff, and Governors.</li>
                <li>Lead and manage all academic and administrative staff at the SOS school. This will include appointments, appraisals, training and development, and when necessary initiate the implementation of disciplinary procedures and make reports to the Director/Managing Committee/Governing Body when necessary.</li>
                <li>Treat all people fairly, equitably and with dignity and respect to create and maintain a positive school culture in line with the school’s Mission Statement.</li>
                <li>Ensure the effective implementation of all school policies, including those that relate to pastoral care, such as behavior, discipline and bullying.</li>
                <li>Foster warm and positive relationships between the school, the local community and all stakeholders.</li>
                <li>Acknowledge the responsibilities and celebrate the achievements of individuals and teams.</li>
                <li>Develop and maintain a realistic culture of high expectations for self and for others and take appropriate action when performance is unsatisfactory.</li>
                <li>Regularly review own practice, set personal targets and take responsibility for own personal development.</li>
                <li>Responsible for ensuring the safety and wellbeing of the pupils at the school;</li>
                <li>Responsible to the Director/Managing Committee/GB for all matters pertaining to SOS.</li>
              </ul>
              <br />
              <h4 className="font-semibold">Learning and Teaching:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Ensure a culture and ethos of challenge and support where all pupils can achieve success and become engaged in their own learning.</li>
                <li>Assess, monitor, and evaluate the quality of teaching standards and learning and the delivery of the curriculum across the board in order to build on success and identify and act on areas of improvement.</li>
                <li>Continue to develop and review curriculum for SOS in keeping with our changing world.</li>
                <li>Monitor and evaluate pupils' attainment levels.</li>
                <li>Demonstrate excellent classroom practice.</li>
                <li>Teach a timetable commensurate with the responsibilities if
                  required.</li>
                <li> Monitor the teaching and learning throughout the school.</li>
                <li> Encourage the further development of a wide ranging extra-curricular activities programme, socio emotional learning, and gender & diversity studies.</li>
              </ul>

              <br />
              <h4 className="font-semibold">Organization – Managing Systems and Resources:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>

                <li>Implement a framework of effective evaluation, assessment and performance management which engages the whole school community. </li>
                <li> Provide effective organisation and management of the school and seek ways of improving organisation structures and functions based on rigorous self-evaluation. </li>
                <li> Take a strategic role in the development of new and emerging technologies to extend and enhance the learning experience of pupils and the wider school community, actively engaging with other schools to build effective learning communities.</li>
                <li>Manage and organise the accommodation effectively and efficiently to ensure that all school buildings meet the needs of the curriculum and health and safety regulations.</li>

              </ul>

              <br />
              <h4 className="font-semibold">Strategic Direction and Shaping the Future:</h4>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Work with the Director and Adviser to maintain a shared vision and future strategic plans which will inspire and motivate pupils, staff, parents and the wider community.</li>
                <li>Ensure that learning is at the centre of strategic planning and resource management.</li>
                <li>Monitor and review all aspects of teaching, attainment, priorities, and targets.</li>
                <li>Promote excellence, equality and high expectations of all pupils, and the wider school community, ensuring a consistent and continuous school wide focus on pupils’ achievement</li>
              </ul>
            </div>

            <div className="text-[#000000c4] mx-4 sm:mx-6 my-4">
              <h2 className="font-semibold">Community:</h2>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Create and maintain a strong sense of community with effective partnerships with all stakeholders that supports and improves pupil’s achievements and personal development.</li>
                <li>Continue to maintain the school culture and ethos.</li>
                <li>Actively promote the school as a centre of excellence for education in the local community.</li>
                <li>Continue to develop links within both the school and the wider community, enabling and promoting excellent communication and relationships between all parties, including neighbouring schools, and all outside support agencies.</li>
                <li>Exercise responsibility for ensuring fair and equal treatment of all employees and customers.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Staff Management and Development:</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Develop effective relationships and communications, which underpin a professional learning community that enables everyone in the school to achieve.</li>
                <li>Create an organisational structure that reflects the school’s values and enables the management systems, structure and processes to work effectively in line with key priorities.</li>
                <li>Ensure effective planning, allocation, support and evaluation of work undertaken by teams and individuals, ensuring clear delegation of tasks and devolution of responsibilities.</li>
                <li>Provide opportunities for growth and continuing professional development of the teaching team by creating an inspiring, professional work environment and modelling behaviour consistent with the school’s values and aspirations.</li>
                <li>Ensure that all staff receive regular performance reviews and have individual professional development plans to address skills gaps.</li>
                <li>Put in place effective team communication mechanisms to ensure that all staff are involved in the school development plan and kept informed of key priorities and developments.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Accountability:</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Ensure individual staff accountabilities are clearly defined, understood and agreed and are subject to rigorous review and evaluation.</li>
                <li>Create and develop an organisation in which all staff recognise that they are accountable for the success of the school. Ensure that all stakeholders are well informed about the school’s direction, priorities, curriculum and pupils’ attainment and progress.</li>
                <li>Combine the outcomes of regular school self-evaluation with external evaluations in order to develop the school.</li>
                <li>Exercise responsibility for ensuring that all new employees receive an induction programme and are properly inducted in the school’s policies and procedures.</li>
              </ul>
              <br />

              <h3 className="font-semibold">Essential Values, Behaviors and Skills</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Change orientation</li>
                <li>Execution orientation</li>
                <li>Operational Excellence</li>
                <li>Passion to serve</li>
                <li>Relationship Building</li>
                <li>Strategic Thinking</li>
                <li>Providing Leadership</li>
              </ul>
              <br />

              <h3 className="font-semibold">Education</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Master’s Degree with minimum 55%</li>
                <li>Regular B. Ed degree with minimum 55%</li>
                <li>CTET qualified is desirable</li>
              </ul>
              <br />


              <h3 className="font-semibold">Experience</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Minimum 10 years of teaching experience and at least 5 years of administrative experience in a recognized Higher Secondary School.</li>
              </ul>
              <br />


              <h3 className="font-semibold">Languages</h3>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>Excellent communication skills English and Hindi. Must have significant and proven writing, presentation and people management skills.</li>
              </ul>
              <br />


              <h3 className="font-semibold">Application process</h3>
              {/* <h5 className="font-semibold"> Jammu branch</h5> */}
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <li>
                  Please email your CV to{' '}
                  <a
                    href="mailto:operations.schools@hermann-gmeiner-edu.org"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    operations.schools@hermann-gmeiner-edu.org
                  </a>{' '}
                  with the subject “Principal, Hermann Gmeiner School, Bhuj".
                </li>

              </ul>
            </div>




          </div>
        </div>
      </div>

    </>
  );
};

export default Careers;

{/* <h3>Essential Skills:</h3>
<ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}> */}

