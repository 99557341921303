import React from "react";
import holidays from '../documents/Holiday List-Students-2024 (1).docx'
const HolidayList =()=>{
    const pdfFiles = [
        { name: ' Hermann Gmeiner School Bhopal', url: holidays},
        { name: ' Hermann Gmeiner School Bhimtal', url: holidays},
        { name: ' Hermann Gmeiner School Anangpur', url: holidays},
        { name: ' Hermann Gmeiner School Palla Nuh', url: holidays },
        { name: ' Hermann Gmeiner School Varansi', url:holidays },
        { name: ' Hermann Gmeiner School Jammu', url: holidays},
        { name: ' Hermann Gmeiner School Faridabad', url:holidays },
    ]
    return (
        <div className="home_main">
          <div className="pdf-container">
          <table className="pdf-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>School Name</th>
                <th>Links</th>
              </tr>
            </thead>
            <tbody>
              {pdfFiles.map((file, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{file.name}</td>
                  <td>
                    <a href={file.url} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    
        </div>
      );
}


export default  HolidayList;