// packages
import Slider from "react-slick";

//images
// import Background from "../images/school-bg.JPG";
import HermanRound from "../images/Ellipse 187.jpg";
import SOSLogo from "../images/Ellipse 186.png";
import CoverPic from "../images/Cover Pic.jpg";

import b from "../images/b.jpg";
import c from "../images/c.jpg";
import d from "../images/d.jpg";
import a from "../images/a.jpg";
import e from "../images/e.jpg";
import f from "../images/f.jpg";

const HomeMainSection = () => {
    const images = [
        {
            image: a,
        },
        {
            image: b,
        },
        {
            image: c,
        },
        {
            image: d,
        },
        {
            image: e,
        },
        {
            image: f,
        },
    ];

    // let settings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 2000,
    //   arrows: false,
    //   slidesToShow: 3,
    //   slidesToScroll: 3,
    //   autoplay: true,
    //   autoplaySpeed: 4000,
    //   pauseOnHover: false,
    // };

    let settings = {
        dots: true,
        speed: 1000,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        // cssEase: "linear",

        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const importAll = (r) => {
        return r.keys().map(r);
    };

    const images1 = importAll(
        require.context(
            "../images/LandingPage",
            false,
            /\.(png|jpe?g|svg|jfif|JPG)$/
        )
    );

    console.log(images1);
    return (
        <div data-aos="fade" className="text-white relative w-full">
            {/* <div className="absolute top-0 left-0 text-white w-full min-h-[100vh] bg-no-repeat bg-cover bg-center flex flex-col justify-center items-center p-8 pb-16 md:py-20 md:px-16 z-10">
        <h1
          data-aos="fade-up"
          data-aos-delay={300}
          className="title-textShadow text-3xl md:text-5xl font-bold text-center md:leading-snug m-0 lg:mx-20 drop-shadow-md"
        >
          Hermann Gmeiner Schools and Training Institutions and their journey
          into Education
        </h1>
      </div> */}
            {/* <Slider {...settings} className="w-full min-h-[86vh]">
        {images.map((item, key) => {
          console.log(item);
          return (
            <div key={key}>
              <div
                className="w-full min-h-screen bg-cover bg-no-repeat bg-center"
                style={{
                  backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${item.image})`,
                }}
              ></div>
            </div>
          );
        })}
      </Slider> */}
            <div>
                <div
                    className="w-full min-h-screen bg-cover bg-no-repeat bg-center bgimageHomepage"
                    style={{
                        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${CoverPic})`,
                    }}
                >
                    <Slider {...settings} className="w-full ">
                        {images1.map((item, key) => {
                            console.log(item);
                            return (
                                <div
                                    className="flex justify-center items-center"
                                    key={key}
                                >
                                    <div className="flex flex-col items-center md:pb-2">
                                        <div className="flex flex-col w-[260px] md:w-[290px] rounded-2xl  p-0  gap-3">
                                            <div className="flex justify-center items-bottom drop-shadow-xl rounded border-4 overflow-hidden">
                                                <img
                                                    className="w-full h-[260px]"
                                                    src={item}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

const Cards = () => {
    return (
        <div>
            <div className="w-full flex flex-col md:flex-row gap-5 md:gap-16 justify-center items-center">
                <Card
                    animation="fade-right"
                    title="SOS Children’s Villages of India"
                    image={SOSLogo}
                    alt="SOS Children’s Villages of India"
                    decription="SOS-CVI was established in 1986 by the then President of SOS-CVI with the sole intention of providing quality education to parentless kids, safe and nurturing spaces for children and also to ensure their comprehensive development."
                />
                <Card
                    animation="fade-left"
                    title="Hermann Gmeiner"
                    image={HermanRound}
                    alt="Hermann Gmeiner"
                    decription="Hermann Gmeiner always had a soft corner for educating homeless kids. Served as a Russian Soldier, he experienced the repercussions of World War II, which eventually made him confront the suffering of many war orphans and homeless kids."
                />
            </div>
        </div>
    );
};

const Card = ({ title, image, decription, alt, animation }) => {
    return (
        <div
            data-aos={animation}
            data-aos-delay={600}
            className="bg-[#c4c4c433] w-[430px] flex flex-col gap-3 justify-center items-center text-center border border-[#c4c4c4] rounded-2xl p-5 backdrop-blur"
        >
            <div className="w-20 md:w-28 overflow-hidden rounded-full">
                <img className="w-full" src={image} alt={alt} />
            </div>
            <div className="font-semibold text-base md:text-xl my-2">
                {title}
            </div>
            <p className="text-center text-xs md:text-sm">{decription}</p>
        </div>
    );
};

export default HomeMainSection;