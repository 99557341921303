// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import AlumniListSection from "../components/AlumniListSection";

// images
import AlumniBgPhoto from "../images/alumni.jpeg";
import Vaishali from "../images/vaishali.jpg";
import Jeevant from "../images/jeevant.jpg";
import Bhawna from "../images/bhawnaY.png";
import Vaibhav from "../images/vaibhav.jpg";
import Rajeev from "../images/rajeev.jpg";
import Bhushan from "../images/bhushan.jpg";
import Anchal from "../images/anchal.jpg";
import Devender from "../images/devender.jpg";

import Abhinav from "../images/abhinav.jpg";
import Manpreet from "../images/manpreet.jpg";
import Adarsh from "../images/adarsh.jpg";
import Prakriti from "../images/prakriti.jpg";
import Sourav from "../images/sourav.jpg";
import Hinani from "../images/hinani.jpg";
import Manish from "../images/manish.jpg";
import Kritika from "../images/kritika.jpg";
import Karandev from "../images/karandev.jpg";
import Rohan from "../images/rohan.jpg";

import meenakshi_kandpal from "../images/meenakshi_kandpal.jpg";
import bikram_jantwal from "../images/bikram_jantwal.jpg";
import manoj_jha from "../images/manoj_jha.jpg";
import monica_yadav from "../images/monica_yadav.jpg";
import operational_architech_UK from "../images/operational_architech_UK.jpg";
import ravinder_basera from "../images/ravinder_basera.jpg";
import kamlesh_bisht from "../images/kamlesh_bisht.jpg";
import shreem_kapil from "../images/shreem_kapil.jpg";
import hemant_kumar from "../images/hemant_kumar.jpg";
import bharat_badana from "../images/bharat_badana.jpg";
import rizwan_khan from "../images/rizwan_khan.jpg";
import mithlesh_joshi from "../images/mithlesh_joshi.jpg";
import sheetal_tripathi from "../images/sheetal_tripathi.jpg";
import pankaj_kandpal from "../images/pankaj_kandpal.jpg";
import himanshu_joshi from "../images/himanshu_joshi.jpg";
import amit_joshi from "../images/amit_joshi.jpg";
import chandan_bisht from "../images/chandan_bisht.jpg";
import anil_bishnoi from "../images/anil_bishnoi.jpg";
import neelima_bhatt from "../images/neelima_bhatt.jpg";
import dhruv_rautela from "../images/dhruv_rautela.jpg";
import ramesh_bhatt from "../images/ramesh_bhatt.jpg";
import rabindro_singh from "../images/rabindro_singh.jpg";
import sanjeev_bhadra from "../images/sanjeev_bhadra.jpg";
import daniel_verma from "../images/daniel_verma.jpg";
import rakesh_palisetty from "../images/rakesh_palisetty.jpg";
import rupesh_pawar from "../images/rupesh_pawar.jpg";
import ankur_raj from "../images/ankur_raj.jpg";
import shaphaba_singh from "../images/shaphaba_singh.jpg";
import sonali_joshi from "../images/sonali_joshi.jpg";
import jagdeep_singh from "../images/jagdeep_singh.jpg";
import alumni_icon from "../images/alumni-icon.svg";
const AwardsPage = () => {
  const data = [
    {
      name: "Col. Kamlesh Bisht",
      image: kamlesh_bisht,
      description: "",
    },
    {
      name: "Lt. Col. Shreem Kapil",
      image: shreem_kapil,
      description: "",
    },
    {
      name: "Lt. Col. Hemant Kumar Belwal",
      image: hemant_kumar,
      description: "",
    },
    {
      name: "Major Dr. Bharat Badana",
      image: bharat_badana,
      description: "",
    },
    {
      name: "Lt. Col. Rizwan R Khan",
      image: rizwan_khan,
      description: "",
    },
    {
      name: "Lt. Col. MITHLESH JOSHI",
      image: mithlesh_joshi,
      description: "",
    },
    {
      name: "Sqdn Leader Sheetal Tripathi",
      image: sheetal_tripathi,
      description: "",
    },
    {
      name: "Group Cap. Pankaj Kandpal",
      image: pankaj_kandpal,
      description: "",
    },
    {
      name: "Sqdn Leader Himanshu Joshi",
      image: himanshu_joshi,
      description: "",
    },
    {
      name: "Cmdr Amit Joshi",
      image: amit_joshi,
      description: "",
    },
    {
      name: "Lt Cmndr Chandan Bisht",
      image: chandan_bisht,
      description: "",
    },
    {
      name: "Lt Cmndr Anil Bishnoi",
      image: anil_bishnoi,
      description: "",
    },
    {
      name: "Hinani Katoch",
      image: Hinani,
      description: "FG Officer in Indian Air Force",
    },
    {
      name: "Kritika Sethi",
      image: Kritika,
      description: "Dy. S.P CBI Lucknow",
    },
    {
      name: "Capt. Manpreet Singh",
      image: Manpreet,
      description: "Comm. Officer",
    },
    {
      name: "Ms. Monica Yadav",
      image: monica_yadav,
      description: "DSP, UP Police",
    },
    {
      name: "Mr. Dhruv Rautela",
      image: dhruv_rautela,
      description: "Ex. OSD Governor of Goa",
    },
    {
      name: "Mr. Devender Chaudhary",
      image: Devender,
      description: "Co-Convenor, Urban Local Bodies of Haryana State, BJP",
    },

    {
      name: "Mr. Ramesh Bhatt",
      image: ramesh_bhatt,
      description: "Ex Media Advisor of CM Uttarakhand",
    },
    {
      name: "Mr. K Rabindro Singh",
      image: rabindro_singh,
      description: "Deputy Speaker Manipur Lagislative Assembly",
    },
    {
      name: "Mr. Bikram S. Jantwal",
      image: bikram_jantwal,
      description: "Finance Controller, Transport Dep., Uttrakhand",
    },
    {
      name: "Mr. Manoj Jha",
      image: manoj_jha,
      description: "Commercial Tax Officer Uttar Pradesh",
    },
    {
      name: "Mr. Ravinder S. Basera",
      image: ravinder_basera,
      description: "State Tax Officer, Uttrakhand",
    },
    {
      name: "Dr. Sourav Dogra",
      image: Sourav,
      description: "Surgeon MS",
    },
    {
      name: "Dr. Prakriti Sharma ",
      image: Prakriti,
      description: "Dermatologist (MD)",
    },
    {
      name: "Dr Konthoujam Shaphaba Singh",
      image: shaphaba_singh,
      description: "MCh Head and Neck Surgery and Oncology, AIIMS New Delhi.",
    },
    {
      name: "Dr. Sonali Joshi",
      image: sonali_joshi,
      description: "Medical Officer Uttarakhand Government",
    },
    {
      name: "Dr Jagdeep Singh",
      image: jagdeep_singh,
      description: "Medical Officer Uttarakhand Government",
    },
    {
      name: "Dr. Adarsh Bhat",
      image: Adarsh,
      description: "Aerospace Engineer IIT Hyderabad",
    },
    {
      name: "Rohan Koul",
      image: Rohan,
      description: "Data Scientist at SAP Labs India PVT. Ltd.",
    },

    {
      name: "Karandev Singh",
      image: Karandev,
      description: "Software Engineer, IIM Indore",
    },
    {
      name: "Manish Bhat",
      image: Manish,
      description: "QA Lead at Expleo Group, Germany",
    },
    {
      name: "Abhinav Chanda",
      image: Abhinav,
      description:
        "Software development Engineer at Anmazon,Toronto. IIT Roorkee",
    },
    {
      name: "Mr. Jeevant",
      image: Jeevant,
      description: "Professional Photographer",
    },
    {
      name: "Ms. Bhawna Yadav",
      image: Bhawna,
      description: "Country HR Head of Malaysia",
    },
    {
      name: "Dr. Rajeev Arora",
      image: Rajeev,
      description: "Dentist",
    },
    {
      name: "Mr. Bhushan Sachdeva",
      image: Bhushan,
      description: "Chartered Accountant",
    },
    {
      name: "Mr. Daniel Verma",
      image: daniel_verma,
      description: "Cloud Channel division Head for UK",
    },
    {
      name: "Ms. Anchal Gulati",
      image: Anchal,
      description: "Anchor – DD News",
    },
    {
      name: "Ms. Meenakshi Kandpal",
      image: meenakshi_kandpal,
      description: "Labour Officer, Uttrakhand",
    },

    // {
    //     name: "",
    //     image: operational_architech_UK,
    //     description: "Operation Architect - AT & T, United Kingdom",
    // },

    {
      name: "Ms. Vaishali",
      image: Vaishali,
      description: "Chartered Accountant",
    },
    {
      name: "Ms. Neelima Bhatt",
      image: neelima_bhatt,
      description:
        "The captain of the women’s cricket team of St Johns Aberfeldie Club at Melbourne",
    },
    {
      name: "Mr. Sanjeev Bhadra",
      image: sanjeev_bhadra,
      description: "Botanical Scientist at Imperial College, London",
    },

    {
      name: "Mr. Rakesh Palisetty",
      image: rakesh_palisetty,
      description:
        "Ph. D. from IIT, Patna, Research Associate, at University of Luxemburg",
    },
    {
      name: "Mr. Rupesh Pawar",
      image: rupesh_pawar,
      description: "Head, Jio-Fiber Care-Service for Maharashtra & Goa",
    },
    {
      name: "Ankur Raj",
      image: ankur_raj,
      description:
        "Governance and foreign accounting analyst (equity) Research fidelity investment Bengaluru, Karnataka India",
    },
    {
      name: "Mr. Vaibhav",
      image: Vaibhav,
      description: "App Developer – Media Tek",
    },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        // title="Our Alumni"
        backgroundImage={AlumniBgPhoto}
        bgImageGradient={0}
        // quote="Education is simply the soul of society as it passes from one generation to another"
        // author="G.K. Chesterton"
      />
      <div className="w-full px-12 pt-10 md:px-40 md:pt-6 ">
        {/* <div data-aos="fade-left" className="h-[6px] w-32  bg-[#1e9ad6]"></div> */}
        <div className="flex items-center">
          <div>
            <img
              src={alumni_icon}
              style={{
                width: "50px",
                height: "50px",
                margin: "0 0.5rem 0 0",
              }}
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay={300}
            className="font-bold text-4xl my-6"
            style={{ color: "#fa3593" }}
          >
            Illustrious Alumni
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-delay={600}
          className="text-[#737373] leading-8 text-2xl"
        >
          Our Alumni are shining bright in the fields of Science, Commerce, and
          Arts. We have given gems to the world in the form of Chartered
          accountants, Journalists, App developers, Government officials, and
          many more.
        </div>
      </div>

      <AlumniListSection data={data} addPhotoFrame={true} />
    </>
  );
};

export default AwardsPage;
