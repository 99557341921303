import PhotoFrameVertical from "../images/photoframe-vertical.png";
import alumni1 from "../images/user-cover-2.jpg";
import { useLocation } from "react-router-dom";

const AlumniList = ({ data, limit, addPhotoFrame, style: propsStyle }) => {
  if (data === undefined) {
    data = [
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
      {
        name: "Name of Alumni",
        image: alumni1,
        description: "Profession",
      },
    ];
  }
  if (limit === undefined) {
    limit = data.length;
  }
  let location = useLocation();
  return (
    <div className="w-full px-12 pb-20 md:px-24 md:pb-10">
      {/* <div className="max-w-full justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 mt-20 gap-16"> */}
      <div className="max-w-full justify-items-center flex flex-wrap justify-center mt-12 gap-12">
        {data.slice(0, limit).map((alumni, index) => {
          return (
            <div
              data-aos="zoom-in"
              data-aos-delay={100}
              className="flex flex-col rounded-xl overflow-hidden shadow-xl w-[230px]"
              key={index}
              style={propsStyle}
            >
              <div
                className="h-[240px] w-full overflow-hidden bg-cover bg-no-repeat bg-top flex items-stretch "
                style={{
                  backgroundImage: `url(${alumni.image})`,
                }}
              >
                {addPhotoFrame === true ? (
                  <img
                    className="w-full h-full "
                    src={PhotoFrameVertical}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
              {alumni.name && (
                <div className="flex flex-col gap-2 py-6 items-center text-center p-2">
                  <div className="font-semibold">{alumni.name}</div>
                  <div className="text-[#737373] text-sm">
                    {alumni.description}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AlumniList;
