import Slider from "react-slick";

// images
import Faridabad from "../images/schools/Faridabad.jpg";
// import Bhimtal from "../images/school-bhimtal-small.jpg";
import Bhimtal from "../images/schools/Bhimtal school.jpg";
import BEd from "../images/schools/B.Ed.jpg";
import Jammu from "../images/schools/Jammu school.jpg";
import Varanasi from "../images/schools/Varanasi School.jpg";
import PallaNuh from "../images/schools/Palla Nuh.jpg";
import Bhuj from "../images/schools/Bhuj.jpg";
import Bhopal from "../images/bhopal.jpg";
import Nizamuddin from "../images/vtc-nz.png";
import Bhakatpara from "../images/bhakatparavtc.jpg";
import Raipur from "../images/vtc-rp.jpeg";
import Anangpur from "../images/schools/Nursing College.jpeg";
import AnangpurSchool from "../images/anangpur-school.jpg";

const Journey = () => {
    let settings = {
        dots: true,
        speed: 1000,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        // cssEase: "linear",
        responsive: [
            {
                breakpoint: 900,

                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,

                settings: {
                    dots: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const JourneyData = [
        {
            year: "1986",
            image: Faridabad,
            name: "Hermann Gmeiner School Faridabad",
            description:
                "The Hermann Gmeiner School faridabad was founded in 1986 by Shri J.N Kaul",
        },
        {
            year: "1987",
            image: Bhimtal,
            name: "Hermann Gmeiner School Bhimtal",
            description: `The Hermann Gmeiner School Bhimtal was founded in 1987 by our
            parental organization SOS-CVI.`,
        },
        {
            year: "1994",
            image: Varanasi,
            name: "Hermann Gmeiner School Varanasi",
            description: `The Hermann Gmeiner School Varanasi was founded in 1994 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "1994",
            image: AnangpurSchool,
            name: "Hermann Gmeiner Primary School Anangpur",
            description: `The Hermann Gmeiner Primary School Anangpur was founded in 1994 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "1997",
            image: PallaNuh,
            name: "Hermann Gmeiner School Palla Nuh",
            description: `The Hermann Gmeiner School Palla Nuh was founded in 1997 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "1998",
            image: Bhopal,
            name: "Hermann Gmeiner School Bhopal",
            description: `The Hermann Gmeiner School Bhopal was founded in 1998 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "1998",
            name: "SOS Nursing School, Anangpur",
            image: Anangpur,
            description: `The SOS Nursing School, Anangpur was founded in 1998 by our 
                parental organization SOS-CVI.`,
        },
        {
            year: "2001",
            image: Jammu,
            name: "Hermann Gmeiner School Jammu",
            description: `The Hermann Gmeiner School Jammu was founded in 2001 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "2005",
            image: BEd,
            name: "Hermann <br/> Gmeiner<br/> Degree<br/> College Bhimtal",
            description: `The Hermann  Gmeiner Degree College. Bhimtal was founded in 2005 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "2006",
            image: Bhuj,
            name: "Hermann Gmeiner Primary School Bhuj",
            description: `The Hermann Gmeiner Primary School Bhuj was founded in 2006 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "2010",
            image: Raipur,
            name: "Vocational Training Centre Raipur",
            description: `The Vocational Training Centre Raipur was founded in 2010 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "2011",
            image: Nizamuddin,
            name: "Vocational Training Centre Nizamuddin",
            description: `The Vocational Training Centre Nizamuddin was founded in 2011 by our
                parental organization SOS-CVI.`,
        },
        {
            year: "2022",
            image: Bhakatpara,
            name: "Vocational Training Centre Bhakatpara",
            description: `The Vocational Training Centre Bhakatpara was founded in 2022 by our
                  parental organization SOS-CVI.`,
        },
    ];

    return (
        <div className="w-full flex flex-col my-28">
            <div
                data-aos="fade-left"
                className="h-[6px] w-32  md:ml-20 bg-[#1e9ad6] mx-auto sm:mx-0"
            ></div>
            <div
                data-aos="fade-left"
                data-aos-delay={300}
                className="font-bold text-center md:text-left text-2xl md:text-3xl my-8 md:my-16 mx-12 md:mx-20"
            >
                Our Journey so far...
            </div>

            <div className="mx-12 md:mx-20 mb-20 hidden lg:block">
                <div
                    data-aos="fade"
                    data-aos-delay={500}
                    className="relative top-[120px] -z-10 h-[5px] rounded-full bg-[#a3e1ff]"
                ></div>
                <div className="flex flex-row max-w-full justify-around">
                    {JourneyData.map((school, index) => {
                        return (
                            <div
                                data-aos="fade"
                                data-aos-delay={500 + index * 100}
                                className={
                                    "flex justify-centr items-center " +
                                    (index % 2 == 0
                                        ? "flex-col"
                                        : "flex-col-reverse mb-[20px]")
                                }
                            >
                                <div className="h-[103px]"></div>
                                <div className="bg-[#1e9ad6] rounded-full w-7 h-7 shrink-0"></div>
                                <div className="bg-[#a3e1ff] w-1 rounded h-7 shrink-0"></div>
                                <div className="text-[#1e9ad6]  text-xl font-semibold">
                                    {school.year}
                                </div>
                                <div
                                    className={`font-semibold text-[#737373] text-sm text-center ${
                                        index % 2 == 0 ? "mt-2" : "-mt-6 mb-3"
                                    }`}
                                    dangerouslySetInnerHTML={{
                                        __html: school.name,
                                    }}
                                />
                                {/* {}
                                </div> */}
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* <div
        data-aos="fade-left"
        data-aos-delay={500}
        className="relative top-[56px] h-[6px] rounded-full mx-12 md:mx-20 bg-[#a3e1ff]"
      ></div> */}
            <div data-aos="fade-left journeySlide" data-aos-delay={700}>
                <Slider {...settings}>
                    {JourneyData.map((school, index) => {
                        return (
                            <div
                                className="flex justify-center items-center"
                                key={index}
                            >
                                <div className="flex flex-col items-center pb-8 md:pb-16">
                                    {/* <div className="text-[#1e9ad6] text-xl font-semibold mb-3">
                    {school.year}
                  </div> */}
                                    {/* <div className="bg-[#1e9ad6] rounded-full w-7 h-7"></div>
                  <div className="bg-[#a3e1ff] h-8 w-1"></div> */}
                                    <div className="flex flex-col w-[260px] md:w-[290px] rounded-2xl bg-[#fafafa] p-3 pb-6 border gap-3 border-[#c9c9c9] h-[19em]">
                                        <div className="flex justify-center items-top rounded overflow-hidden">
                                            <img
                                                className="w-full"
                                                src={school.image}
                                                alt=""
                                            />
                                        </div>
                                        <div className="font-semibold text-[#737373] text-sm text-center">
                                            {school.name?.replace(
                                                /<br\/>/g,
                                                " "
                                            )}
                                        </div>
                                        <div className="text-xs  text-[#737373] text-center leading-5">
                                            {school.description}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default Journey;
