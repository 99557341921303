const AboutTheInstitute = ({ data }) => {
    return (
        <div data-aos="fade" className="w-full mt-6 mb-6 snap-y">
            {data.map((item, index) => {
                let order =
                    "flex flex-col items-center md:items-start md:flex-row gap-8 text-lg text-[#737373]";
                if (index !== 0 && index % 2 === 0) {
                    order += " md:flex-row-reverse";
                }
                return (
                    <div
                        data-aos="fade"
                        className={`w-full flex flex-col px-12 md:px-20 pb-2 snap-center ${
                            item.padding ? item.padding : "py-4"
                        }`}
                        key={index}
                    >
                        {item.headerStripe !== false && (
                            <div
                                data-aos="fade-left"
                                data-aos-delay={200}
                                className="w-28 h-[7px] bg-[#1e9ad6]"
                            ></div>
                        )}

                        <div
                            data-aos="fade-left"
                            data-aos-delay={400}
                            className="text-3xl lg:text-4xl font-bold mt-8 mb-4 flex"
                        >
                            {item.icon && (
                                <div>
                                    <img
                                        src={item.icon}
                                        alt="chek"
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            margin: item.iconMargin,
                                        }}
                                    />
                                </div>
                            )}
                            <div
                                style={{ color: item.headingColor }}
                                className="flex  items-center "
                            >
                                {item.heading}
                            </div>
                        </div>
                        {/* <div className={order}>
                            {!item.isIntroPara && (
                                <div
                                    data-aos={
                                        index % 2 === 0
                                            ? "fade-left"
                                            : "fade-right"
                                    }
                                    data-aos-delay={600}
                                    className={`w-full md:w-1/3 lg:w-2/6 overflow-hidden object-cover rounded-lg border`}
                                >
                                    <img
                                        className="w-full"
                                        src={item.image}
                                        alt=""
                                    />
                                </div>
                            )}
                            {item.content && (
                                <div
                                    data-aos={
                                        index % 2 === 1
                                            ? "fade-right"
                                            : "fade-left"
                                    }
                                    data-aos-delay={800}
                                    className={`  ${
                                        item.isIntroPara
                                            ? "md:w-full lg:w-full"
                                            : "md:w-2/3 lg:w-4/6"
                                    } text-sm md:text-xl lg:leading-9 shrink-0`}
                                >
                                    {item.content}
                                    <br />
                                </div>
                            )}
                        </div> */}
                        <div
                            data-aos={
                                index % 2 === 1 ? "fade-right" : "fade-left"
                            }
                            data-aos-delay={800}
                            className={`  ${
                                item.isIntroPara
                                    ? "md:w-full lg:w-full"
                                    : "w-full"
                            } text-sm mt-3 md:text-xl lg:leading-9 shrink-0 text-[#737373]`}
                        >
                            {item.content}
                        </div>
                        <div
                            data-aos={
                                index % 2 === 1 ? "fade-right" : "fade-left"
                            }
                            data-aos-delay={800}
                            className={`  ${
                                item.isIntroPara
                                    ? "md:w-full lg:w-full"
                                    : "w-full"
                            } text-sm mt-3 md:text-xl lg:leading-9 shrink-0 text-[#737373]`}
                        >
                            {item.content2}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default AboutTheInstitute;
