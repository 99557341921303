// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import PageListSection from "../components/PageListSection";

// images
import VTCBgPhoto from "../images/vtc_bg_1.png";
import Nizamuddin from "../images/vtc-nizamuddin.jpg";
import Raipur from "../images/vtc_raipur_bike.png";
// import PallaNuh from "../images/vtc_palla_nuh.jpeg";

const VTCPage = () => {
  const data = [
    {
      heading: "Vocational Training Centre, Nizamuddin",
      image: Nizamuddin,
      content: `It was initiated by SOS Children’s Villages of India at Nizamuddin, 
					  New Delhi in 2011.  Youths between the age of 18-25 years are trained 
					  in this training centre. The courses offered by the VTC are recognized 
					  and approved by the Directorate General of Employment & Training under 
					  the Skills Development Initiatives- Modular Employability Scheme, 
					  Government of India. Below given are the courses offered.`,
    },
    {
      heading: "Vocational Training Centre, Raipur",
      image: Raipur,
      content: `The objective of this Training is to provide employable skill training 
					  to below poverty line unemployed youth and early school drops out 
					  students of Chhattisgarh state for their better employability. We have 
					  experienced Instructors, good learning infrastructure with vibrant 
					  learning space, course patterns are designed to support the academic 
					  and professional growth of trainees.`,
    },
    // {
    //     heading: "Vocational Training Centre, Palla Nuh",
    //     image: PallaNuh,
    //     content: `The school, SOS HGS, Palla Nuh was established as a community project in
    //               1997. The main purpose of this school is to uplift the educational standard
    //               of community children for the village of Palla in Nuh District and all other
    //               nearby villages. Later this school was affiliated to Haryana Board of Secondary
    //               Education for secondary level in 2005. Presently the student strength of this
    //               school is 605 with 283 female students. Modern and qualitative education
    //               is the main aim of this school with the help of thematic curriculum for LKG
    //               and HKG, smart classes and experiential learning.`,
    // },
  ];

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection
        title="Vocational Training Centres"
        backgroundImage={VTCBgPhoto}
        quote="Education is the key to unlocking the golden door of freedom."
        author="George Washington Carver"
        bgImageGradient={0}
      />
      <PageListSection data={data} />
    </>
  );
};

export default VTCPage;
