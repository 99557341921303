// packages
import { useLayoutEffect } from "react";

// containers
import TopSection2 from "../components/TopSection2";
import PageListSection from "../components/PageListSection";
import Phone from "../images/phone.png";
import PedagogiSkill from "./PedagogiSkill";
// images
import PedagogyBgPhoto from "../images/pedagogy-cover.jpg";
import { FiMusic } from "react-icons/fi";
import { BiRun } from "react-icons/bi";
import { ImManWoman } from "react-icons/im";
import { GiBlackBook } from "react-icons/gi";
import { IoCalculatorOutline, IoManSharp } from "react-icons/io5";
import { RiPlantLine } from "react-icons/ri";
import { BsCamera } from "react-icons/bs";
import topicon from "../images/pedagogyTopIcon.svg";
import p1 from "../images/blooms.jpg";
import p2 from "../images/p2.png";
import p3 from "../images/p3.png";
//images 2 
// import pedagogi_cover from "../images/newcovers/Pedagogical Approach Cover pic.jpg";
// src/images/careers_cover.jpg


const PedagogyPage = () => {
  const Card = ({ title, text, border, bg, Cicon }) => {
    return (
      <div
        style={{
          boxShadow: "rgb(0 0 0 / 25%) 10px 8px 4px",
          margin: "30px 10px",
          width: "240px",
        }}
        className={`h-46 border-8 ${border} rounded-2xl overflow-hidden`}
      >
        <div className={`flex py-2 justify-center ${bg}`}>{Cicon}</div>

        <div className="px-3 py-4">
          <div className="font-bold text-xl mb-2">{title}</div>
          <div className="text-base ">{text}</div>
        </div>
      </div>
    );
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <TopSection2
        // title="Pedagogy"
        backgroundImage={PedagogyBgPhoto}
        bgImageGradient={0}

        // quote="Children must be taught how to think, not what to think."
        // author="Margaret Mead"
      />
      <div className="w-full h-full px-12 md:px-20 py-6 snap-center">
        <div className="flex">
          <img
            className="pedagogi_image"
            src={topicon}
            alt=""
            style={{ width: "80px" }}
          />
          <h1 className="text-4xl mt-4 font-semibold text-[#1fdc00d1]">
            Pedagogical Approach
          </h1>
        </div>
        <div className="flex gap-2">
          <div>
            <div className="text-[#000000c4] font-medium mx-6 my-8">
              At Hermann Gmeiner School, a variety of teaching-learning
              methodologies, integrated with technology, are used to give
              students a holistic education making them independent, life-long
              learners and above all responsible citizens of the globe. WE
              believes in holistic development of its students and leaves no
              stone unturned to nurture talent. An integrated approach to
              education is adopted at each level. A broad and comprehensive
              education should aim at enabling each individual to discover,
              unearth and enrich his or her creative potential.The holistic
              curriculum encourages students to be active learners who explore,
              understand and participate in the world around them.
            </div>
            <div className="text-[#000000b8] font-semibold text-3xl mx-6 my-12">
              <h3>Employing Bloom’s Taxonomy to develop learning objectives</h3>
            </div>
            <div className=" my-15 flex justify-center">
              <img className="mb-16" src={p1} />
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              Bloom's Taxonomy is employed in classrooms of Hermann Gmeiner to
              help the teachers in understanding the objectives of classroom
              teaching. It guides them to change the complexity of the questions
              and helps students to achieve higher levels of understanding.
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4 ">
              The curriculum is designed in such a way that the levels help to
              move students through the process of learning, from the most
              fundamental remembering and understanding to the more complex
              evaluating and creating.
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              Because Bloom's Taxonomy is based on a specific hierarchy of
              learning levels, each level is a vital part of learning to achieve
              deeper, more advanced cognitive skills and abilities. Building
              upon each level in lesson plans guides students to think in
              "increasingly more sophisticated ways".
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              The Taxonomy is used as a practical tool, providing a framework in
              which to plan challenging lessons that help to ensure students'
              progress is maximised – a fundamental tenet of successful
              teaching. The framework also allows educators to assess learning
              on an ongoing basis, encouraging students to reflect on their
              progress.
            </div>
            <div className="text-[#000000b8] font-semibold text-3xl mx-6 my-12">
              <h3>Developing 21st Century Skills</h3>
            </div>
            <PedagogiSkill />
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              21st-century skills are more important to students now than ever
              before. By providing a framework for successful learning in the
              classroom, we ensure students can thrive in a world where change
              is constant and learning never stops.
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              These are tools that are tremendously important for our nation's
              well-being and can be universally applied to enhance ways of
              thinking, learning, working and living in the world. The skills
              include critical thinking/reasoning, creativity/creative thinking,
              problem solving, metacognition, collaboration, communication and
              global citizenship.
            </div>
            <div className="text-[#000000c4] font-medium mx-6 my-4">
              We equip teachers to use new teaching strategies or educational
              software, giving them hands-on demonstrations and offering
              concrete entry points to facilitate development of these skills in
              their classrooms. Personalized learning involves students in their
              own learning process, teaching them several valuable skills.
            </div>
            <div className="text-[#000000c5] font-semibold text-3xl mx-6 my-12">
              <h3>Project Based Learning Activities</h3>
            </div>
            <div className="flex mx-6  ">
              <div className="text-[#000000c4] font-medium ">
                Project-based learning is adopted as a dynamic classroom
                approach in which students actively explore real-world problems
                and challenges and acquire a deeper knowledge. Project based
                learning activities allow our students to develop deep content
                knowledge. Importantly, they also support development of 21st
                century skills such as critical thinking, collaboration,
                creativity and communication. Relevant PBL activities are
                conducted under the guided supervision of aur able staff to
                develop greater depth of understanding the concepts than in
                traditional classroom-based learning resulting in improved
                levels of student's creativity.
              </div>
              <img alt="p2" src={p2} />
            </div>

            <div className="  ">
              <h3 className="text-[#000000c5] font-semibold text-3xl mx-6 my-2">
                Experiential Learning
              </h3>
            </div>
            <div className="flex mx-6  gap-8">
              <div className="w-full mt-5">
                <img src={p3} alt="p3" />
              </div>

              <div className="text-[#000000c4] mt-20 font-medium text-base ">
                At Hermann Gmeiner, we believe in experiential learning
                methodology in order to make learning fun filled, interesting
                and meaningful. We encourage students to ask questions and
                explore answers to become critical thinkers of the future
                generation. By engaging students in hands-on experiences and
                reflection, they are better able to connect theories and
                knowledge learned in the classroom to real-world situations.
              </div>
            </div>
            <div className="mt-20 mx-6">
              <h3 className="text-[#000000c5] font-semibold text-3xl  my-2">
                Multiple intelligences in the classroom
              </h3>
            </div>
            <div className="text-[#000000c4] mx-6 mt-6  font-medium text-lge ">
              No two children think and learn the same way, so, it is necessary
              to present information in a completely different light to reach
              some students. The theory of multiple intelligences provides
              teachers of HGS with a framework and tools that allows them to
              better meet the needs of the different types of learners present
              in every classroom.
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center mx-6 text-start  flex-wrap gap-4">
        <Card
          title="Music Smart"
          text="Musical Intelligence"
          border="border-[#97eef7]"
          bg="bg-[#0adbf2]"
          Cicon={<FiMusic color="white" size="45" />}
        />
        <Card
          title="Body Smart"
          text="Bodily-Kinesthetic Intelligence"
          border="border-[#f69dfc]"
          bg="bg-[#d41fe0]"
          Cicon={<BiRun color="white" size="45" />}
        />
        <Card
          title="People Smart"
          text="Interpersonal Intelligence"
          border="border-[#a5e060]"
          bg="bg-[#66a61e]"
          Cicon={<ImManWoman color="white" size="45" />}
        />
        <Card
          title="Word Smart"
          text="Linguistic Intelligence"
          bg="bg-[#ba0c09]"
          border="border-[#f5605d]"
          Cicon={<GiBlackBook color="white" size="45" />}
        />
      </div>
      <div className="flex justify-center mx-6 mb-12 text-start  flex-wrap gap-4">
        <Card
          title="Logic Smart"
          text="Logical-Mathematical Intelligence"
          border="border-[#be8ced]"
          bg="bg-[#7620c7]"
          Cicon={<IoCalculatorOutline color="white" size="45" />}
        />
        <Card
          title="Nature Smart"
          text="Naturalistic"
          border="border-[#8877fc]"
          bg="bg-[#290af2]"
          Cicon={<IoManSharp color="white" size="45" />}
        />
        <Card
          title="Self Smart"
          text="Intrapersonal Intelligence"
          border="border-[#f7e881]"
          bg="bg-[#f0d211]"
          Cicon={<RiPlantLine color="white" size="45" />}
        />
        <Card
          title="Picture Smart"
          text="Spatial Intelligence"
          border="border-[#5cf78f]"
          bg="bg-[#0dd14e]"
          Cicon={<BsCamera color="white" size="45" />}
        />
      </div>

      {/* <PageListSection data={data} /> */}
    </>
  );
};

export default PedagogyPage;