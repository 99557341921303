import Slider from "react-slick";

const PhotoGallery = () => {
  let settings = {
    className: "slider variable-width",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    infinite: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const importAll = (r) => {
    return r.keys().map(r);
  };

  const images1 = importAll(
    require.context(
      "../images/photo-gallery",
      false,
      /\.(png|jpe?g|svg|jfif|JPG)$/
    )
  );

  return (
    <div data-aos="fade" className="w-full my-24">
      <div
        data-aos="fade-left"
        data-aos-delay={300}
        className="h-[6px] w-32 ml-12 md:ml-20 bg-[#1e9ad6]"
      ></div>
      <div
        data-aos="fade-left"
        data-aos-delay={500}
        className="font-bold text-3xl md:text-4xl mx-12 md:mx-20 my-12"
      >
        Photo Gallery
      </div>
      <div data-aos="fade-left" data-aos-delay={700}>
        <Slider {...settings}>
          {images1.map((image, index) => {
            return (
              <div className="flex justify-center items-center" key={index}>
                <div className="h-[250px] md:h-[400px] rounded-2xl object-cover overflow-hidden mx-12 md:ml-20">
                  <img className="h-full" src={image} alt="" />
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default PhotoGallery;
