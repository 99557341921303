// packages
import { useLayoutEffect } from "react";

// components
import TopSection from "../components/TopSection";
import AwardsListSection from "../components/AwardsListSection";

// images
import AwardsBgPhoto from "../images/awards-bg.jpg";
import fit_india_school from "../images/fit_india_school.jpeg";
import Award2 from "../images/awards2.png";
import robo_genius_award from "../images/robo_genius_award.jpg";
import national_school_award from "../images/national_school_award.jpg";
import global_sustainable_award_stage from "../images/global_sustainable_award_stage.jpg";
import global_sustainable_award_school from "../images/global_sustainable_award_school.jpg";
import cleanliness_award from "../images/cleanliness_award_2.jpeg";
import award_bhim_1 from "../images/award_bhim_1.jpg";
import award_bhim_2 from "../images/award_bhim_2.jpg";
import award_bhim_3 from "../images/award_bhim_3.jpg";
import award_bhim_5 from "../images/award_bhim_5.jpg";
import fbd_award_1 from "../images/fbd_award_1.jpg";
import ach_icon from "../images/ach-icon.png";

const AwardsPage = () => {
    const data = [
        {
            image: award_bhim_5,
            name: "Top Day-Cum-Boarding School",
            school: "Hermann Gmeiner School Bhimtal",
            date: "2022",
        },
        {
            image: award_bhim_2,
            name: "Earthian-Paryavaran Mitra",
            school: "Hermann Gmeiner School Bhimtal",
            date: "2021",
        },
        {
            image: fit_india_school,
            name: `FIT India Movement`,
            school: "Hermann Gmeiner School Bhimtal",
            date: "2021",
        },
        {
            image: award_bhim_1,
            name: "Education",
            school: "Hermann Gmeiner School Bhimtal",
            date: "2020",
        },
        {
            image: cleanliness_award,
            name: "Best School for Cleanliness by “Adani Group”",
            school: "Hermann Gmeiner School Bhopal",
            date: "2019",
        },

        {
            image: award_bhim_3,
            name: "Green Mission",
            school: "Hermann Gmeiner School Bhimtal",
            date: "2018",
        },
        {
            image: fbd_award_1,
            name: "Most Socially Active School",
            school: "Hermann Gmeiner School Faridabad",
            date: "2018",
        },
        {
            image: national_school_award,
            name: `Prominent School in sports by National School Awards`,
            school: "Hermann Gmeiner School Jammu",
            date: "2018",
        },
        {
            image: global_sustainable_award_stage,
            name: `Golden Category Award`,
            school: "Hermann Gmeiner School Jammu",
            date: "2017",
        },
        {
            image: global_sustainable_award_school,
            name: `Golden Category Award`,
            school: "Hermann Gmeiner School Jammu",
            date: "2017",
        },
        {
            image: Award2,
            name: `Excellence Certificate by “Bharat  Shiksha Puraskar”`,
            school: "",
            date: "2016",
        },
        {
            image: robo_genius_award,
            name: `Notable contribution in advocating STEM`,
            school: "Hermann Gmeiner School Jammu",
            date: "2014",
        },
    ];

    const importAll = (r) => {
        return r.keys().map(r);
    };

    const images = importAll(
        require.context(
            "../images/Awards2",
            false,
            /\.(png|jpe?g|svg|jfif|JPG)$/
        )
    );

    console.log(images);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <TopSection
                // title="Awards & Accolades"
                backgroundImage={AwardsBgPhoto}
                // quote="It doesn’t matter how many times you win an award, it is always special."
                // author="Zinedine Zidane"
            />
            <div className="w-full pt-32 md:pt-10 px-12 md:px-20">
                {/* <div data-aos="fade-left" className="h-[6px] w-32  bg-[#1e9ad6]"></div> */}
                <div className="flex items-center">
                    <div>
                        <img
                            src={ach_icon}
                            style={{
                                width: "50px",
                                height: "50px",
                                margin: "0 0.5rem 0 0",
                            }}
                        />
                    </div>
                    <div
                        data-aos="fade-left"
                        data-aos-delay={300}
                        className="font-bold text-4xl my-6"
                        style={{ color: "#1fdc00" }}
                    >
                        Achievements & Laurels
                    </div>
                </div>
                <div
                    data-aos="fade-left"
                    data-aos-delay={600}
                    className="text-[#737373] leading-8 text-2xl"
                >
                    We take pride in the National and International recognitions
                    our schools and training institutions have received for
                    their work.
                </div>
            </div>
            <AwardsListSection data={images} addPhotoFrame={false} />
        </>
    );
};

export default AwardsPage;
